import React, { useContext, useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import { CompanyContext } from '../../../store';
import OpaMovilService from '../../../api/OpaMovilService';
import * as CryptoJS from 'crypto-js';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useNavigate, Link as LinkRouter } from "react-router-dom";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

export function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://opa.com.co/">
        OPA S.A.S
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  security_image: {
    objectFit: 'contain'
  }
}));

export function OpaMovilRegister() {
  const theme = useContext(CompanyContext);
  let navigate = useNavigate();

  const classes = useStyles();
  const [cedula, setCedula] = useState("");
  const [usuario, setUsuario] = useState("");
  const [clave1, setClave1] = useState("");
  const [clave2, setClave2] = useState("");
  const [celular, setCelular] = useState("");
  const [loading, setLoading] = useState(false);
  const [dialongAlert, setDialongAlert] = useState(false);
  const [dialongAlertText, setDialongAlertText] = useState("");
  const [dialongCodigo, setDialongCodigo] = useState(false);
  const [codigo, setCodigo] = useState("");
  const [volverAlLogin, setVolverAlLogin] = useState(false);

  const handleAlertClose = () => {
    setDialongAlert(false);
    if (volverAlLogin) {
        navigate("/login");
    }
  };
  
  const _secret = process.env.REACT_APP_OPAMOVIL_SECRET || ""

  const validateInputs = () => {
    // validate empty fields
    if(!cedula.trim() || !usuario.trim() || !clave1.trim() || !clave2.trim() || !celular.trim()){
      setDialongAlertText("Faltan campos por llenar ...Verifique por favor")
      setDialongAlert(true)
      return false;
    }

    // validate cedula only numbers
    if(!Number(cedula)) {
      setDialongAlertText("Cedula invalida ...Verifique por favor")
      setDialongAlert(true)
      return false;
    }
    // validate usuario min max
    if(usuario.length < 8 || usuario.length > 15 ) {
      setDialongAlertText("Usuario minimo 8 y maximo 15 letras ...Verifique por favor")
      setDialongAlert(true)
      return false;
    }

    if(clave1.length < 4 || clave1.length > 6 ) {
      setDialongAlertText("Clave minimo 4 y maximo 6 letras ...Verifique por favor")
      setDialongAlert(true)
      return false;
    }

    if(celular.length < 5 || celular.length > 10 ) {
      setDialongAlertText("Celular no valido ...Verifique por favor")
      setDialongAlert(true)
      return false;
    }

    if(clave1 !== clave2){
      setDialongAlertText("Las contraseñas no coinciden ...Verifique por favor")
      setDialongAlert(true)
      return false;
    }

    return true;
  }

  const register = async () => {
    setLoading(true);
    // validations
    const resultValidationInputs = validateInputs();

    if (!resultValidationInputs) {
      setLoading(false);
      return
    };

    const params = {
      nuevousuario: CryptoJS.AES.encrypt(usuario, _secret).toString(),
      cedula: CryptoJS.AES.encrypt(cedula, _secret).toString(),
      contrasena: CryptoJS.AES.encrypt(clave1.toString(), _secret).toString(),
      celular: CryptoJS.AES.encrypt(celular.toString(), _secret).toString(),
      codigo: '',
    }

    const api = new OpaMovilService(theme.api_url);

    try {
      const response = await api.nuevousuario(params);
      var Mensaje

      if(response[0][0].codigo.replace(/ /g, "") === 'GEN') {
        Mensaje = CryptoJS.AES.decrypt(
          response[0][0].Mensaje,
          _secret
        );
        response[0][0].Mensaje = CryptoJS.enc.Utf8.stringify(Mensaje); 

        alertMsm(response[0][0].Mensaje, true)
      } else if(response[0][0].codigo.replace(/ /g, "") === '855'){
        setDialongAlertText("Su número de celular no coincide con la base de datos. Comuníquese con su Entidad")
        setDialongAlert(true)
      } else {
        Mensaje = CryptoJS.AES.decrypt(
          response[0][0].Mensaje,
          _secret
        );
        response[0][0].Mensaje = CryptoJS.enc.Utf8.stringify(Mensaje); 

        setDialongAlertText(response[0][0].Mensaje)
        setDialongAlert(true)
      }
    } catch (error) {
      console.log(error)
      return alert("Error en el servicio. intente mas tarde")
    }

    setLoading(false);
  }
  
  const alertMsm = (mensaje: string, pedir: boolean) => {
    if (pedir) {
      setDialongCodigo(true);
    }

    setDialongAlertText(mensaje)
    setDialongAlert(true)
  }

  const validarCodigo = async () => {
    const codigoIngreso = codigo.replace(/ /g, "");
    
    if (!codigoIngreso) return;

    try {
      const params = {
        nuevousuario: CryptoJS.AES.encrypt(usuario, _secret).toString(),
        cedula: CryptoJS.AES.encrypt(cedula, _secret).toString(),
        contrasena: CryptoJS.AES.encrypt(clave1.toString(), _secret).toString(),
        celular: CryptoJS.AES.encrypt(celular.toString(), _secret).toString(),
        codigo: CryptoJS.AES.encrypt(codigoIngreso, _secret).toString()
      }
      
      const api = new OpaMovilService(theme.api_url);
      const response = await api.nuevousuario(params);

      var Mensaje = CryptoJS.AES.decrypt(
        response[0][0].Mensaje,
        _secret
      );

      response[0][0].Mensaje = CryptoJS.enc.Utf8.stringify(Mensaje); 

      let respuestaCodigo = response[0][0].codigo.replace(/ /g, "")
      
      setDialongAlertText(response[0][0].Mensaje)

      if (respuestaCodigo === "025") {
        setVolverAlLogin(true)
        setDialongCodigo(false)
      }

      setDialongAlert(true)
    } catch (error) {
      console.log(error)
      setDialongCodigo(false)
      setDialongAlertText("Código no válido, por favor vuelva a realizar el proceso de ingreso")
      setDialongAlert(true)
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <Dialog
        open={dialongCodigo}
        onClose={() => setDialongCodigo(false)}
        aria-labelledby="alert-dialog-title-codigo"
        aria-describedby="alert-dialog-description-codigo"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description-codigo">
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="codigo"
              label="Código de verificación"
              name="codigo"
              autoFocus
              value={codigo}
              onChange={e => setCodigo(e.target.value)}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={validarCodigo} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      
      <Dialog
        open={dialongAlert}
        onClose={handleAlertClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialongAlertText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAlertClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <CssBaseline />
      <div className={classes.paper}>
        <h1>Registro de Usuario</h1>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            type="number"
            id="cedula"
            label="Cedula"
            name="cedula"
            autoComplete="cedula"
            autoFocus
            value={cedula}
            onChange={e => setCedula(e.target.value)}
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="usuario"
            label="Usuario"
            name="usuario"
            autoComplete="usuario"
            autoFocus
            value={usuario}
            onChange={e => setUsuario(e.target.value)}
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="clave1"
            label="Contraseña"
            name="clave1"
            autoComplete="clave1"
            type="password"
            autoFocus
            value={clave1}
            onChange={e => setClave1(e.target.value)}
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            type="password"
            id="clave2"
            label="Confirme Contraseña"
            name="clave2"
            autoComplete="clave2"
            autoFocus
            value={clave2}
            onChange={e => setClave2(e.target.value)}
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            type="number"
            id="celular"
            label="Celular"
            name="celular"
            autoComplete="celular"
            autoFocus
            value={celular}
            onChange={e => setCelular(e.target.value)}
          />

          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={register}
            disabled={loading}
          >
            Registrarse
          </Button>

          {loading? 
            <div style={{textAlign: 'center', width: '100%'}}>
              <CircularProgress />
            </div> : null
          }

          <Grid container>
            <Grid item>
              <LinkRouter to="/login">
                {"¿Ya tienes una cuenta?"}
              </LinkRouter>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}