import React, { useEffect, useContext, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { CompanyContext } from '../../store';
import OpaMovilService from '../../api/OpaMovilService';
import * as CryptoJS from 'crypto-js';
import moment from 'moment';

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
});

export enum ECoSignerType {
    CODEUDORES = "CODEUDORES",
    CODEUDORDE = "CODEUDORDE",
}

interface IAccountTypeProps {
    type: ECoSignerType;
    title?: string;
}

const CoSigner = ({ type, title }: IAccountTypeProps) => {
    const classes = useStyles();
    const theme = useContext(CompanyContext);
    const _secret = process.env.REACT_APP_OPAMOVIL_SECRET || "";

    const [detail, setDetail] = useState([]);

    let params = {
        cedula: CryptoJS.AES.encrypt(localStorage.getItem("opa:movil:cedula") || "", _secret).toString(),
        pag: 0
    }

    useEffect(() => {
        if (!type) return;
        findCosigners()
        // eslint-disable-next-line
    }, [type])

    const findCosigners = async ()=> {
        const token = sessionStorage.getItem("opa:movil:jwt")
        const api = new OpaMovilService(theme.api_url, token);
        try {
            const response = await (type === ECoSignerType.CODEUDORES?  api.misCodeudores(params) : api.CodeudorDe(params))
            setDetail(response[0] || [])
        } catch (error) {
            console.log(error)
        }
    }

    return <>
        {title? <Toolbar>
                <Typography variant="h6" id="tableTitle" component="div">
                    {title}
                </Typography>
            </Toolbar>: null
        }
        
        <TableContainer>
            <Table className={classes.table}  size="small" aria-label="simple table">
                <TableHead>
                    {type === ECoSignerType.CODEUDORES? 
                        <TableRow>
                            <TableCell>Cedula</TableCell>
                            <TableCell>Nombre Integrado</TableCell>
                            <TableCell>Pagare</TableCell>
                            <TableCell>Telefono</TableCell>
                            <TableCell>Direccion</TableCell>
                        </TableRow> : 
                    type === ECoSignerType.CODEUDORDE?
                        <TableRow>
                            <TableCell>Nombre Integrado</TableCell>
                            <TableCell>Pagare</TableCell>
                            <TableCell>Fecha Prestamo</TableCell>
                            <TableCell>Fecha Ultimo Pago</TableCell>
                            <TableCell>Fecha Vencimiento</TableCell>
                            <TableCell>Telefono</TableCell>
                            <TableCell align="right">Cuota</TableCell>
                            <TableCell align="right">Saldo</TableCell>
                            <TableCell align="right">Plazo</TableCell>
                        </TableRow>
                    : null
                    }
                </TableHead>
                <TableBody>
                {detail.map((row, index) => (
                    type === ECoSignerType.CODEUDORES?
                        <TableRow key={index}>
                            <TableCell>{row['cedula']}</TableCell>
                            <TableCell>{row['nombreintegrado']}</TableCell>
                            <TableCell>{row['pagare']}</TableCell>
                            <TableCell>{row['telefono']}</TableCell>
                            <TableCell>{row['direccion']}</TableCell>
                        </TableRow> : 
                    type === ECoSignerType.CODEUDORDE?
                        <TableRow key={index}>
                            <TableCell>{row['nombreintegrado']}</TableCell>
                            <TableCell>{row['pagare']}</TableCell>
                            <TableCell>{moment.utc(row['f_cargoprestamo']).format('YYYY-MM-DD')}</TableCell>
                            <TableCell>{moment.utc(row['f_ultimopago']).format('YYYY-MM-DD')}</TableCell>
                            <TableCell>{moment.utc(row['F_vence']).format('YYYY-MM-DD')}</TableCell>
                            <TableCell>{row['telefono']}</TableCell>
                            <TableCell align="right">{row['anualidad']}</TableCell>
                            <TableCell align="right">{row['saldo']}</TableCell>
                            <TableCell align="right">{row['plazo']}</TableCell>
                        </TableRow>
                    : null
                ))}
                </TableBody>
            </Table>
        </TableContainer>
    </>
}


export default CoSigner;