import axios from "axios";

class Configuration {
  baseURL = process.env.REACT_APP_BASE_URL;
  user = "";
  API = {};

  constructor(base, token, user, prefix) {
    if (base) this.baseURL = base;

    const baseURL = prefix ? this.baseURL + prefix : this.baseURL;
    this.API = axios.create({ baseURL });
    this.API.interceptors.response.use(function (response) {
        if(response.data && response.data[0] && response.data[0][0]) {
            // verify if the token is expired
            if(response.data[0][0].Codigo === "401") {
                // if the token is expired, then redirect to login page
                sessionStorage.clear();
                localStorage.clear();
                window.location.href = "/login";
            }
        }

        return response;
    }, function (error) {
        return Promise.reject(error);
    });

    if (token)
      this.API.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    if (user) this.user = user;
  }

  handleResponseError(response) {
    throw new Error("HTTP error, status = " + response.status);
  }

  handleError(error) {
    // eslint-disable-next-line no-console
    console.log(error.message);
  }

  handleUnauthorize(response) {
    console.log(response);
    return response;
  }
}

export default Configuration;
