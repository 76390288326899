import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { CompanyContext } from '../../store';
import OpaMovilService from '../../api/OpaMovilService';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import RefreshIcon from '@material-ui/icons/Refresh';
import SendIcon from '@material-ui/icons/Send';
import PrintIcon from '@material-ui/icons/Print';

import Backdrop from '@material-ui/core/Backdrop';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';
import Modal from '../../components/Modal';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    formControl: {
        '& > *': {
            width: `calc(100% - ${theme.spacing(2)})`,
            margin: theme.spacing(1),
        },
    },
    containerActionsSimulator: {
        display: "flex",
        '& > *': {
            width: `calc(50% - ${theme.spacing(0.5)})`,
        },
    },
    containerActionsSimulatorSpace: {
        width: `${theme.spacing(1)}`
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        background: 'linear-gradient(75deg, rgba(255, 255, 255, 0.48) 0%, rgb(255, 255, 255) 100%)'
    },
    table: {},
}));


interface NumberFormatCustomProps {
    inputRef: (instance: NumberFormat | null) => void;
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

interface IFormInput {
    destination: string;
    term: string;
    periodicityDays: string;
    capitalization: string;
    amount: string;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
        prefix="$"
      />
    );
}

const SimulatorAH = () => {
    const classes = useStyles();
    const theme = useContext(CompanyContext);
    const token = sessionStorage.getItem("opa:movil:jwt");
    const { control, handleSubmit, reset, getValues } = useForm<IFormInput>();

    const [ destinations, setDestinations ] = useState<{codlinea: string, nombre: string, tasaCaptacion: number}[]>([]);
    const [ terms, setTerms ] = useState<{Value: string}[]>([]);
    const [ periodicityDays, setPeriodicityDays ] = useState<{Value: string}[]>([]);

    const [ loading, setLoading ] = useState(false);
    const [openModal, setOpenModal] = useState(0)
    const [urlPage, setUrlPage] = useState({})
    
    const [ paramsSimulation, setParamsSimulation ] = useState<{}>({});
    const [ resultSimulation, setResultSimulation ] = useState<{
        TasaInteresNominal?: number;
        TasaPeriodica?: number;
        ValorNetaEntregar?: number;
        InteresNetoEntregar?: number;
        TotalRte?: number;
        TotalCapital?: number;
        Tasaperiodica?: number;
    }>({});

    const onSubmit: SubmitHandler<IFormInput> = async(data) => {
        const api = new OpaMovilService(theme.api_url, token);
        setLoading(true)
        try {
            const params = { 
                Capital: data.amount,
                capitalizacion: data.capitalization,
                Destino: data.destination,
                periodoDias: data.periodicityDays,
                plazo: data.term,
                Totales: true
            };

            const responseSimulation = await api.simuladorAhorrosCalcular(params);

            setParamsSimulation(params)
            setResultSimulation(responseSimulation[0][0])
        } catch (e) {
            console.log(e)
        }

        setLoading(false)
    };

    const clearForm = () => {
        reset({
            destination: "",
            term: "",
            periodicityDays: "",
            capitalization: "",
            amount: ""
        });

        setResultSimulation({})
    }

    const getDestinations = async () => {
        const api = new OpaMovilService(theme.api_url, token);

        try {
            const responseDestinations = await api.simuladorAhorrosBuscarDestinosAhorros();
            setDestinations(responseDestinations[0]);
        } catch (e) {
            console.log(e)
        }
    }

    const getTerms = async (codeDestination: string) => {
        const api = new OpaMovilService(theme.api_url, token);

        try {
            const responseTerms = await api.simuladorAhorrosBuscarPlazoAhorros(codeDestination);
            setTerms(responseTerms[0]);
        } catch (e) {
            console.log(e)
        }
    }

    const getPeriodicityDays = async (term: string) => {
        const api = new OpaMovilService(theme.api_url, token);

        try {
            const destinationSeleted = getValues('destination')
            const responsePeriodicityDays = await api.simuladorAhorrosComboperiodoliquida(destinationSeleted, term);
            setPeriodicityDays(responsePeriodicityDays[0]);
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        getDestinations()
        // eslint-disable-next-line
    }, [])

    const downloadDetail = async () => {
        const api = new OpaMovilService(theme.api_url, token);
        setLoading(true)
        try {
            const responseBuffer = await api.simuladorAhorrosImprimirFront(paramsSimulation);

            const blobPDF = new Blob([responseBuffer], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(blobPDF);

            setOpenModal(Math.random())
            setUrlPage(fileURL)
        } catch (e) {
            console.log(e)
        }
        setLoading(false)
    }


    return <div className={classes.root}>
        <Modal status={openModal} documentUrl={urlPage} />
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Paper className={classes.paper}> <h1>Simulador CDAT</h1> </Paper>
            </Grid>

            <Grid item xs={12} md={4}>
                <Card className={classes.paper}>
                    <form className={classes.formControl} onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                        <Controller
                            name="destination"
                            control={control}
                            defaultValue=""
                            render={({ field }) => <TextField
                                select
                                size="small"
                                label="Destino"
                                SelectProps={{
                                    native: true,
                                }}
                                variant="outlined"
                                {...field}
                                onChange={ev => {
                                    field.onChange(ev)
                                    getTerms(ev.target.value)
                                }}
                            >
                                <option aria-label="None" value="" />
                                {destinations.map( (item, index) => 
                                    <option key={`destinations${index}`} value={item.codlinea}>{item.nombre}</option>
                                )}
                            </TextField>}
                        />

                        <Controller
                            name="term"
                            control={control}
                            defaultValue=""
                            render={({ field }) => <TextField
                                select
                                size="small"
                                label="Plazo"
                                SelectProps={{
                                    native: true,
                                }}
                                variant="outlined"
                                {...field}
                                onChange={ev => {
                                    field.onChange(ev)
                                    getPeriodicityDays(ev.target.value)
                                }}
                            >
                                <option value="" />
                                {terms.map( (item, index) => 
                                    <option key={`terms${index}`} value={item.Value}>{item.Value}</option>
                                )}
                            </TextField>}
                        />
                        
                        <Controller
                            name="periodicityDays"
                            control={control}
                            defaultValue=""
                            render={({ field }) => <TextField
                                select
                                size="small"
                                label="Periodo Interés (Días)"
                                SelectProps={{
                                    native: true,
                                }}
                                variant="outlined"
                                {...field}
                            >
                                <option value="" />
                                {periodicityDays.map( (item, index) => 
                                    <option key={`periodicityDays${index}`} value={item.Value}>{item.Value}</option>
                                )}
                            </TextField>}
                        />

                        <Controller
                            name="capitalization"
                            control={control}
                            defaultValue=""
                            render={({ field }) => <TextField
                                select
                                size="small"
                                label="Capitalización"
                                SelectProps={{
                                    native: true,
                                }}
                                variant="outlined"
                                {...field}
                            >
                                <option value="" />
                                <option value="1">Si</option>
                                <option value="2">No</option>
                            </TextField>}
                        />

                        <Controller
                            name="amount"
                            control={control}
                            defaultValue=""
                            render={({ field }) => <TextField
                                label="Capital Ahorrar"
                                variant="outlined"
                                size="small"
                                InputProps={{
                                    inputComponent: NumberFormatCustom as any,
                                }}
                                {...field} 
                            />}
                        />
                        
                        <Grid className={classes.containerActionsSimulator}>
                            <Button variant="contained" type="submit" color="primary" disableElevation startIcon={<SendIcon />}>
                                Simular
                            </Button>
                            <div className={classes.containerActionsSimulatorSpace}></div>
                            <Button variant="contained" disableElevation onClick={clearForm} startIcon={<RefreshIcon />}>
                                Limpiar
                            </Button>
                        </Grid>
                        
                    </form>
                </Card>
            </Grid>
            <Grid item xs={12} md={8}>
                <TableContainer component={Card}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell width="25%">Cuota</TableCell>
                                <TableCell width="25%">Capital a Ahorrar</TableCell>
                                <TableCell width="25%">Interes a Entregar</TableCell>
                                <TableCell width="25%">Total a Entregar</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell align="left">
                                    <NumberFormat value={resultSimulation.InteresNetoEntregar || 0} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                </TableCell>
                                <TableCell align="letf">
                                    <NumberFormat value={resultSimulation.TotalCapital || 0} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                </TableCell>
                                <TableCell align="letf">
                                    <NumberFormat value={resultSimulation.InteresNetoEntregar || 0} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                </TableCell>
                                <TableCell align="left">
                                    <NumberFormat value={resultSimulation.ValorNetaEntregar || 0} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <br></br>
                <TableContainer component={Card}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell width="25%">Tasa Nominal</TableCell>
                                <TableCell width="25%">Total Retención</TableCell>
                                <TableCell width="25%">Tasa Periódica</TableCell>
                                <TableCell width="25%">Tasa Efectiva</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>{resultSimulation.TasaInteresNominal}</TableCell>
                                <TableCell>
                                    <NumberFormat value={resultSimulation.TotalRte || 0} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                </TableCell>
                                <TableCell>{resultSimulation.TasaPeriodica}</TableCell>
                                <TableCell>{resultSimulation.Tasaperiodica}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                {resultSimulation.ValorNetaEntregar?
                    <>
                        <br></br>
                        <Button variant="contained" color="primary" onClick={downloadDetail} disableElevation startIcon={<PrintIcon />}>
                            Descargar Detalle
                        </Button>
                    </> : null
                }
            </Grid>
        </Grid>
        <Backdrop open={loading} className={classes.backdrop}>
            <img src={process.env.PUBLIC_URL + '/car.gif'} />
        </Backdrop>
    </div>
}

export default SimulatorAH;