import React, { useContext, useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import { CompanyContext } from '../../../store';
import OpaMovilService from '../../../api/OpaMovilService';
import * as CryptoJS from 'crypto-js';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useNavigate, Link as LinkRouter } from "react-router-dom";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Copyright } from './OpaMovil';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  security_image: {
    objectFit: 'contain'
  }
}));

export function OpaMovilRecoverUser() {
  const theme = useContext(CompanyContext);
  let navigate = useNavigate();

  const classes = useStyles();
  const [cedula, setCedula] = useState("");
  const [celular, setCelular] = useState("");
  const [loading, setLoading] = useState(false);
  const [dialongAlert, setDialongAlert] = useState(false);
  const [dialongAlertText, setDialongAlertText] = useState("");
  const volverAlLogin = false

  const handleAlertClose = () => {
    setDialongAlert(false);
    if (volverAlLogin) {
        navigate("/login");
    }
  };
  
  const _secret =  process.env.REACT_APP_OPAMOVIL_SECRET || ""

  const validateInputs = () => {
    // validate empty fields
    if(!cedula.trim() || !celular.trim()){
      setDialongAlertText("Faltan campos por llenar ...Verifique por favor")
      setDialongAlert(true)
      return false;
    }

    // validate cedula only numbers
    if(!Number(cedula)) {
      setDialongAlertText("Cedula invalida ...Verifique por favor")
      setDialongAlert(true)
      return false;
    }

    if(celular.length < 5 || celular.length > 10 ) {
      setDialongAlertText("Celular no valido ...Verifique por favor")
      setDialongAlert(true)
      return false;
    }

    return true;
  }

  const recordarUsuario = async () => {
    setLoading(true);
    // validate fields
    const resultValidationInputs = validateInputs()
    
    if (!resultValidationInputs) {
        setLoading(false);
        return
    };

    const params = {
        cedula: CryptoJS.AES.encrypt(cedula, _secret).toString(),
        celular: CryptoJS.AES.encrypt(celular.toString(), _secret).toString()
    }

    const api = new OpaMovilService(theme.api_url);
    let respuestaCodigo = ""

    try {
        const response = await api.recordarusuario(params);
        var Mensaje = CryptoJS.AES.decrypt(
            response[0][0].Mensaje,
            _secret
        );

        response[0][0].Mensaje = CryptoJS.enc.Utf8.stringify(Mensaje);

        setDialongAlertText(response[0][0].Mensaje)
        setDialongAlert(true)

        

        if(response[0][0].codigo !== undefined){
            respuestaCodigo = response[0][0].codigo.replace(/ /g, "")
        }else{
            respuestaCodigo = "000"
        }
    } catch (error) {
        console.log(error)
        return alert("Error en el servicio. intente mas tarde")
    }

    setLoading(false);

    if(respuestaCodigo !== '855'){
        return navigate("/login");
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <Dialog
        open={dialongAlert}
        onClose={handleAlertClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialongAlertText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAlertClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <CssBaseline />
      <div className={classes.paper}>
        <h1>Recordar Usuario</h1>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            type="number"
            id="cedula"
            label="Cedula"
            name="cedula"
            autoComplete="cedula"
            autoFocus
            value={cedula}
            onChange={e => setCedula(e.target.value)}
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            type="number"
            id="celular"
            label="Celular"
            name="celular"
            autoComplete="celular"
            autoFocus
            value={celular}
            onChange={e => setCelular(e.target.value)}
          />

          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={recordarUsuario}
            disabled={loading}
          >
            Enviar
          </Button>

          {loading? 
            <div style={{textAlign: 'center', width: '100%'}}>
              <CircularProgress />
            </div> : null
          }

          <Grid container>
            <Grid item>
              <LinkRouter to="/login">
                {"¿Ya tienes una cuenta?"}
              </LinkRouter>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}