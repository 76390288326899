import Configuration from "./Configuration";

class OpaCDService {
  constructor(base, token) {
    this.config = new Configuration(base, token, null, "creditos-digitales/api/");

    // configuration for app movil not send Bearer format
    this.config.API.defaults.headers.common["Authorization"] = `${token}`;
  }

  async configuration() {
    const params = { esoperador: 'N' }

    return this.config.API.post(`config`, params).then(response => {
      if (response.status !== 200) {
        return this.config.handleResponseError(response);
      }
      return response.data;
    });
  }

  async cdToken() {
    const params = { esoperador: 'N' }

    return this.config.API.post(`token`, params).then(response => {
      if (response.status !== 200) {
        return this.config.handleResponseError(response);
      }
      return response.data;
    });
  }
}

export default OpaCDService;
