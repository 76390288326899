import PropTypes from 'prop-types';
import { useMemo } from 'react';
// material
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider, createTheme, StyledEngineProvider } from '@material-ui/core/styles';
//
import shape from './shape';
import { paletteLight } from './palette';
import typography from './typography';
import GlobalStyles from './globalStyles';
import componentsOverride from './overrides';
import shadows, { createCustomShadow, LIGHT_MODE } from './shadows';

// ----------------------------------------------------------------------

ThemeConfig.propTypes = {
  children: PropTypes.node,
  palleteConfig: {}
};

export default function ThemeConfig({ children, palleteConfig }) {
  const themeOptions = useMemo(
    () => ({
      palette: Object.assign(paletteLight, palleteConfig),
      shape,
      typography,
      shadows,
      customShadows: createCustomShadow(LIGHT_MODE)
    }),
    [palleteConfig]
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
