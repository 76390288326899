import React, { useContext, createContext, useState, ReactNode } from "react";
import {
  Route,
  Navigate,
} from "react-router-dom";

interface IProps {
  children: ReactNode;
  path?: string;
}

interface IAuthContext {
  user?: string | null;
  signin?: (jwt: string) => void;
  signout?: () => void;
}

const authContextValues: IAuthContext = {
  user: sessionStorage.getItem("opa:movil:jwt")
}

const authContext = createContext(authContextValues);

export function ProvideAuth({ children }: IProps) {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}

export function useAuth() {
  return useContext(authContext);
}

export function useProvideAuth() {
  const [user, setUser] = useState<string | null>(sessionStorage.getItem("opa:movil:jwt"));

  const signin = (jwt: string) => {
    return setUser(jwt);
  };

  const signout = () => {
    // remove storage
    sessionStorage.clear();
    localStorage.clear();

    return setUser(null);
  };

  return {
    user,
    signin,
    signout
  };
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
export function PrivateRoute({ children, ...rest }: IProps) {
  let auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user ? (
          children
        ) : <Navigate to="/login" replace state={{ from: location }} />
      }
    />
  );
}
