import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { forwardRef, useContext } from 'react';
import { CompanyContext } from "../store";
// material
import { Box } from '@material-ui/core';

// ----------------------------------------------------------------------

const Page = forwardRef(({ children, title = '', ...other }, ref) => {
    const theme = useContext(CompanyContext);

    return (
        <Box ref={ref} {...other}>
          <Helmet>
            <title>{`${theme.name} | Sucursal Virtual`}</title>
            <link rel="icon" type="image/png" href={theme.logo_url} />
          </Helmet>
          {children}
        </Box>
    );      
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string
};

export default Page;
