import React from 'react';
import {
  Navigate,
  useRoutes
} from "react-router-dom";

import Dashboard from "./layouts/dashboard";

import { useAuth } from "./auth/ProvideAuth";

import OpaMovilRecoverUser from './view/Login/RecoverUser';
import OpaMovilRecoverPassword from './view/Login/RecoverUserPass';
import OpaMovilRegister from './view/Login/RegisterUser';

import Accounts from './view/Accounts';
import SimulatorCR from './view/SimulatorCR';
import PayrollDiscounts from './view/PayrollDiscounts';
import Profile from './view/Profile';
import SimulatorAH from './view/SimulatorAH';
import Certificates from './view/Certificates';
import AccountDetail from './view/Accounts/Detail';
import Login from './view/Login';

const routes = (isLoggedIn: boolean) => [
    {
      path: '/',
      element: !isLoggedIn ? <Navigate to="/login" /> : <Navigate to="/home/accounts" />,
    },
    {
      path: '/home',
      element: isLoggedIn ? <Dashboard /> : <Navigate to="/login" />,
      children: [
        { path: '/accounts', element: <Accounts /> },
        { path: '/account/:productType/:productId', element: <AccountDetail /> },
        { path: '/simulatorCR', element: <SimulatorCR /> },
        { path: '/certificates', element: <Certificates /> },
        { path: '/simulatorAH', element: <SimulatorAH /> },
        { path: '/profile', element: <Profile /> },
        { path: '/profile/change-password', element: <OpaMovilRecoverPassword /> },
        { path: '/payrollDiscounts', element: <PayrollDiscounts /> },
        { path: '/', element: <Navigate to="/home/accounts" /> },
      ],
    },
    { path: 'login', element: <Login /> },
    { path: 'register', element: <OpaMovilRegister /> },
    { path: 'recover-user', element: <OpaMovilRecoverUser /> },
    { path: 'recover-password', element: <OpaMovilRecoverPassword /> },
    
];

export const Home = () => {
    let auth = useAuth();

    const routing = useRoutes(routes(!!auth.user));

    return (
        <>
          {routing}
        </>
    );
}