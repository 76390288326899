import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import {
    Link
} from "react-router-dom";
import NumberFormat from 'react-number-format';

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
});

export enum EAccountType {
    AHORROS = "AHORROS",
    CREDITOS = "CREDITOS",
    NOVEDADES = "NOVEDADES",
    DEBITOS = "DEBITOS"
}

interface IAccountTypeProps {
    type: EAccountType;
    title?: string;
    data?: never[];
}

const AccountType = ({ type, title, data }: IAccountTypeProps) => {
    const classes = useStyles();

    const [products, setProducts] = useState([]);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        if (!data) return;
        setProducts(data)
        let temporalTotal = 0;
        
        data.map(item => {
            switch (type) {
                case EAccountType.AHORROS:
                    temporalTotal += Number((item["SaldoTotalCuenta"] || "0").toString().replace(/,/g, "")) 
                    break;
                case EAccountType.CREDITOS:
                    temporalTotal += Number((item["SaldoCapital"] || "0").toString().replace(/,/g, ""))
                    break;
                case EAccountType.NOVEDADES:
                    temporalTotal = 0
                    break;
                case EAccountType.DEBITOS:
                    temporalTotal = 0
                    break;
                default:
                    temporalTotal = 0
                    break;
            }
        })

        setTotal(temporalTotal)

    }, [data])

    return <>
        {title? <Toolbar>
                <Typography variant="h6" id="tableTitle" component="div">
                    {title}
                </Typography>
            </Toolbar>: null
        }

        <TableContainer>
            <Table className={classes.table}  size="small" aria-label="simple table">
                <TableHead>
                    {type === EAccountType.AHORROS? 
                        <TableRow>
                            <TableCell>Concepto</TableCell>
                            <TableCell>Cuenta</TableCell>
                            <TableCell>Fecha Apertura</TableCell>
                            <TableCell>Fecha Vencimiento</TableCell>
                            <TableCell align="right">Cuota</TableCell>
                            <TableCell align="right">Disponible</TableCell>
                            <TableCell align="right">Interes</TableCell>
                        </TableRow> : 
                    type === EAccountType.CREDITOS?
                        <TableRow>
                            <TableCell>Concepto</TableCell>
                            <TableCell>Pagare</TableCell>
                            <TableCell>Fecha Apertura</TableCell>
                            <TableCell>Plazo</TableCell>
                            <TableCell>Fecha Vencimiento</TableCell>
                            <TableCell align="right">Cuota</TableCell>
                            <TableCell align="right">Saldo</TableCell>
                        </TableRow> :
                    type === EAccountType.NOVEDADES?
                        <TableRow>
                            <TableCell>Novedad</TableCell>
                            <TableCell>Numero</TableCell>
                            <TableCell>Pagada Hasta</TableCell>
                            <TableCell align="right">Saldo Inicial</TableCell>
                            <TableCell align="right">Cuota</TableCell>
                            <TableCell align="right">Saldo</TableCell>
                        </TableRow> :
                    type === EAccountType.DEBITOS?
                        <TableRow>
                            <TableCell>Concepto</TableCell>
                            <TableCell>Cuenta</TableCell>
                            <TableCell>Fecha Apertura</TableCell>
                            <TableCell>Fecha Vencimiento</TableCell>
                            <TableCell align="right">Cuota</TableCell>
                            <TableCell align="right">Disponible</TableCell>
                            <TableCell align="right">Interes</TableCell>
                        </TableRow>
                    : null
                    }
                </TableHead>
                <TableBody>
                {products.map((row, index) => (
                    type === EAccountType.AHORROS?
                        <TableRow key={index}>
                            <TableCell>{row['NombreLineaAhorros']}</TableCell>
                            <TableCell>
                                <Link to={'/home/account/' + type + '/' + row['NumeroCuentaAhorros'] + '?type=' + row['Tipo'] }>{row['NumeroCuentaAhorros']}</Link>
                            </TableCell>
                            <TableCell>{moment.utc(row['FechaAperturaCuenta']).format('YYYY-MM-DD')}</TableCell>
                            <TableCell>{
                                moment.utc(row['FechaVenceCuenta']).format('YYYY-MM-DD') === '1900-01-01' ? '----' :
                                moment.utc(row['FechaVenceCuenta']).format('YYYY-MM-DD')
                            }</TableCell>
                            <TableCell align="right">{row['ValorCuotaAhorro']}</TableCell>
                            <TableCell align="right">{row['SaldoTotalCuenta']}</TableCell>
                            <TableCell align="right">{row['InteresCausadoCuenta']}</TableCell>
                        </TableRow> : 
                    type === EAccountType.CREDITOS?
                        <TableRow key={index}>
                            <TableCell>{row['nombredestino']}</TableCell>
                            <TableCell>
                                <Link to={'/home/account/' + type + '/' + row['Pagare'] }>{row['Pagare']}</Link>
                            </TableCell>
                            <TableCell>
                                {
                                    row['FApertura'] ? moment.utc(row['FApertura']).format('YYYY-MM-DD') :  
                                    row['Fecha'] ? moment.utc(row['Fecha']).format('YYYY-MM-DD') : '----'
                                }
                            </TableCell>
                            <TableCell>{row['cuotasapagar']}</TableCell>
                            <TableCell>
                                {
                                    row['FvenceCR'] ? moment.utc(row['FvenceCR']).format('YYYY-MM-DD') :
                                    row['F_venceCR'] ? moment.utc(row['F_venceCR']).format('YYYY-MM-DD') : '----'
                                }
                            </TableCell>
                            <TableCell align="right">{row['Anualidad']}</TableCell>
                            <TableCell align="right">{row['SaldoCapital']}</TableCell>
                        </TableRow> :
                    type === EAccountType.NOVEDADES?
                        <TableRow key={index}>
                            <TableCell>{row['NombreNovedad']}</TableCell>
                            <TableCell>
                                <Link to={'/home/account/' + type + '/' + row['codnovedad'] + '?type=' + row['NumeroNovedad']  }>{row['codnovedad']}-{row['NumeroNovedad']}</Link>
                            </TableCell>
                            <TableCell>{moment.utc(row['NovedadPagadaHasta']).format('YYYY-MM-DD')}</TableCell>
                            <TableCell align="right">{row['saldoinicial']}</TableCell>
                            <TableCell align="right">{row['CuotaNovedad']}</TableCell>
                            <TableCell align="right">{row['SaldoNovedad']}</TableCell>
                        </TableRow> :
                    type === EAccountType.DEBITOS?
                        <TableRow key={index}>
                            <TableCell>{row['NombreLineaAhorros']}</TableCell>
                            <TableCell>
                                <Link to={'/home/account/' + type + '/' + row['NumeroCuentaAhorros'] }>{row['NumeroCuentaAhorros']}</Link>
                            </TableCell>
                            <TableCell>{moment.utc(row['FechaAperturaCuenta']).format('YYYY-MM-DD')}</TableCell>
                            <TableCell>{moment.utc(row['FechaVenceCuenta']).format('YYYY-MM-DD')}</TableCell>
                            <TableCell align="right">{row['ValorCuotaAhorro']}</TableCell>
                            <TableCell align="right">{row['SaldoTotalCuenta']}</TableCell>
                            <TableCell align="right">{row['InteresCausadoCuenta']}</TableCell>
                        </TableRow>
                    : null
                ))}

                {
                    type === EAccountType.AHORROS?
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell align="right">
                                <strong> <NumberFormat value={total} displayType={'text'} thousandSeparator={true} prefix={''} decimalScale={2} fixedDecimalScale={true} /> </strong>
                            </TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow> : 
                    type === EAccountType.CREDITOS?
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell align="right">
                                <strong> <NumberFormat value={total} displayType={'text'} thousandSeparator={true} prefix={''} decimalScale={2} fixedDecimalScale={true} /> </strong>
                            </TableCell>
                        </TableRow> :
                    type === EAccountType.NOVEDADES? null:
                    type === EAccountType.DEBITOS? null:
                    null
                }
                </TableBody>
            </Table>
        </TableContainer>
    </>
}


export default AccountType;