import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { CompanyContext } from '../../store';
import OpaMovilService from '../../api/OpaMovilService';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import { useForm, useFieldArray, Controller, SubmitHandler } from "react-hook-form";
import RefreshIcon from '@material-ui/icons/Refresh';
import SendIcon from '@material-ui/icons/Send';
import AddIcon from '@material-ui/icons/Add';
import PrintIcon from '@material-ui/icons/Print';

import SnackbarContent from '@material-ui/core/SnackbarContent';

import Backdrop from '@material-ui/core/Backdrop';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';
import Modal from '../../components/Modal';

interface NumberFormatCustomProps {
    inputRef: (instance: NumberFormat | null) => void;
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

interface IExtraPayment {
    installment_number: string;
    installment_amount: string;
}

interface IFormInput {
    term: string;
    amount: string;
    periodicity: string;
    loan: string;
    loanDestination: string;
    extraPayments: IExtraPayment[];
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
        prefix="$"
      />
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    formControl: {
        '& > *': {
            width: `calc(100% - ${theme.spacing(2)})`,
            margin: theme.spacing(1),
        },
    },
    containerExtraPayment: {
        display: "flex",
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    containerActionsSimulator: {
        display: "flex",
        '& > *': {
            width: `calc(50% - ${theme.spacing(0.5)})`,
        },
    },
    containerActionsSimulatorSpace: {
        width: `${theme.spacing(1)}`
    },
    table: {},
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        background: 'linear-gradient(75deg, rgba(255, 255, 255, 0.48) 0%, rgb(255, 255, 255) 100%)'
    },
}));

const SimulatorCR = () => {
    const theme = useContext(CompanyContext);
    const token = sessionStorage.getItem("opa:movil:jwt");
    const classes = useStyles();

    const { control, handleSubmit, reset } = useForm<IFormInput>();
    const { fields, append, remove } = useFieldArray({ control, name: "extraPayments" });

    const [ loands, setLoands ] = useState<{codlinea: string}[]>([]);
    const [ loandDestinations, setLoandDestinations ] = useState<{nombredestino: string, coddestino: string}[]>([]);

    const [ loading, setLoading ] = useState(false);

    const [ paramsSimulation, setParamsSimulation ] = useState({});
    const [openModal, setOpenModal] = useState(0)
    const [urlPage, setUrlPage] = useState({})

    const [ resultSimulation, setResultSimulation ] = useState<{
        Cuota?: number,
        SaldoCapital?: number,
        Plazo?: number,
        Periodicidad?: string,
        TasaPeriodica?: number,
        TotalInteresCorriente?: number,
        Mensaje?: string
    }>({});

    const getLoans = async () => {
        const api = new OpaMovilService(theme.api_url, token);

        try {
            const responseLoans = await api.simuladorCreditosBuscarLineas();
            setLoands(responseLoans[0]);
        } catch (e) {

        }
    }

    const getLoanDestinations = async (loan: string) => {
        const api = new OpaMovilService(theme.api_url, token);

        try {
            const responseLoanDestinations = await api.simuladorCreditosBuscarDestinos(loan);
            setLoandDestinations(responseLoanDestinations[0]);
        } catch (e) {

        }
    }

    useEffect(() => {
        getLoans()
        // eslint-disable-next-line
    }, [])

    const appendExtraPayment = () => {
        append({
            installment_number: '',
            installment_amount: '',
        });
    };

    const onSubmit: SubmitHandler<IFormInput> = async(data) => {
        const api = new OpaMovilService(theme.api_url, token);
        setLoading(true)
        try {
            const params = {
                objeto: {
                    CodLinea: data.loan,
                    Coddestino: data.loanDestination,
                    periodicidad: data.periodicity,
                    Capitalprestar: data.amount,
                    plazo: data.term,
                    cedula: localStorage.getItem("opa:movil:cedula"),
                    cuotasExtras: (data.extraPayments) ? data.extraPayments.map(item => {
                        return { Numero: item.installment_number, Abono: item.installment_amount }
                    }) : []
                }
            };

            const responseSimulation = await api.simuladorCreditosCalcular(params);
            setParamsSimulation(params)
            setResultSimulation(responseSimulation)
        } catch (e) {
            console.log(e)
        }

        setLoading(false)
    };

    const clearForm = () => {
        reset({
            term: "",
            amount: "",
            periodicity: "",
            loan: "",
            loanDestination: "",
            extraPayments: []
        });

        setResultSimulation({})
    }

    const downloadDetail = async () => {
        const api = new OpaMovilService(theme.api_url, token);
        setLoading(true)
        try {
            const responseBuffer = await api.simuladorCreditosImprimirFront(paramsSimulation);

            const blobPDF = new Blob([responseBuffer], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(blobPDF);
            

            setOpenModal(Math.random())
            setUrlPage(fileURL)
        } catch (e) {
            console.log(e)
        }
        setLoading(false)
    }

    return <div className={classes.root}>
        <Modal status={openModal} documentUrl={urlPage} />
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Paper className={classes.paper}> <h1>Simulador De Creditos</h1> </Paper>
            </Grid>

            <Grid item xs={12} md={4}>
                <Card className={classes.paper}>
                    <form className={classes.formControl} onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                        <Controller
                            name="loan"
                            control={control}
                            defaultValue=""
                            render={({ field }) => <TextField
                                select
                                size="small"
                                label="Linea"
                                SelectProps={{
                                    native: true,
                                }}
                                variant="outlined"
                                {...field}
                                onChange={ev => {
                                    field.onChange(ev)
                                    getLoanDestinations(ev.target.value)
                                }}
                            >
                                <option aria-label="None" value="" />
                                {loands.map((item, index) => 
                                    <option key={`loands${index}`} value={item.codlinea}>{item.codlinea}</option>
                                )}
                                
                            </TextField>}
                        />

                        <Controller
                            name="loanDestination"
                            control={control}
                            defaultValue=""
                            render={({ field }) => <TextField
                                select
                                size="small"
                                label="Destino"
                                SelectProps={{
                                    native: true,
                                }}
                                variant="outlined"
                                {...field}
                            >
                                <option value="" />
                                {loandDestinations.map((item, index) => 
                                    <option key={`loandDestinations${index}`} value={item.coddestino}>{item.nombredestino}</option>
                                )}
                            </TextField>}
                        />
                        
                        <Controller
                            name="periodicity"
                            control={control}
                            defaultValue=""
                            render={({ field }) => <TextField
                                select
                                size="small"
                                label="Periodicidad"
                                SelectProps={{
                                    native: true,
                                }}
                                variant="outlined"
                                {...field}
                            >
                                <option value="" />
                                <option value="30">Mensual</option>
                                <option value="15">Quincenal</option>
                                <option value="10">Decadal</option>
                                <option value="7">Semanal</option>
                            </TextField>}
                        />

                        <Controller
                            name="amount"
                            control={control}
                            defaultValue=""
                            render={({ field }) => <TextField
                                label="Monto a prestar"
                                variant="outlined"
                                size="small"
                                InputProps={{
                                    inputComponent: NumberFormatCustom as any,
                                }}
                                {...field} 
                            />}
                        />
                        
                        <Controller
                            name="term"
                            control={control}
                            defaultValue=""
                            render={({ field }) => <TextField
                                label="Plazo"
                                variant="outlined"
                                size="small"
                                type="number"
                                {...field}
                            />}
                        />
                        
                        <fieldset hidden={fields.length <= 0}>
                            <legend>Cuotas extras</legend>

                            {fields.map((question, extraPaymentIndex) => (
                                <div key={question.id} className={classes.containerExtraPayment}>
                                    <Controller
                                        name={`extraPayments.${extraPaymentIndex}.installment_number`}
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => <TextField
                                            type="number"
                                            label="# Cuota"
                                            variant="outlined"
                                            size="small"
                                            {...field}
                                        />}
                                    />

                                    <Controller
                                        name={`extraPayments.${extraPaymentIndex}.installment_amount`}
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => <TextField
                                            label="Valor extra"
                                            variant="outlined"
                                            size="small"
                                            InputProps={{
                                                inputComponent: NumberFormatCustom as any,
                                            }}
                                            {...field}
                                        />}
                                    />

                                    <IconButton
                                        color="primary"
                                        size="small"
                                        onClick={() => {
                                            remove(extraPaymentIndex);
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </div>
                            ))}
                        </fieldset>

                        <Button variant="contained" color="secondary" disableElevation onClick={appendExtraPayment} startIcon={<AddIcon />}>
                            Agregar Cuota Extra
                        </Button>
                        
                        <Grid className={classes.containerActionsSimulator}>
                            <Button variant="contained" type="submit" color="primary" disableElevation startIcon={<SendIcon />}>
                                Simular
                            </Button>
                            <div className={classes.containerActionsSimulatorSpace}></div>
                            <Button variant="contained" disableElevation onClick={clearForm} startIcon={<RefreshIcon />}>
                                Limpiar
                            </Button>
                        </Grid>
                        
                    </form>
                </Card>
            </Grid>
            <Grid item xs={12} md={8}>
                {resultSimulation.Mensaje?
                    <>
                        <SnackbarContent message={resultSimulation.Mensaje} />
                        <br></br>
                    </> : null
                }
                
                <TableContainer component={Card}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell width="33%">Capital</TableCell>
                                <TableCell width="33%">Plazo</TableCell>
                                <TableCell width="33%">Cuota</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell align="left">
                                    <NumberFormat value={resultSimulation.SaldoCapital || 0} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                </TableCell>
                                <TableCell>{resultSimulation.Plazo}</TableCell>
                                <TableCell align="left">
                                    <NumberFormat value={resultSimulation.Cuota || 0} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <br></br>
                <TableContainer component={Card}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell width="33%">Periodicidad</TableCell>
                                <TableCell width="33%">Tasa</TableCell>
                                <TableCell width="33%">Intereses</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>{resultSimulation.Periodicidad}</TableCell>
                                <TableCell>{(resultSimulation.TasaPeriodica || 0).toFixed(2)}</TableCell>
                                <TableCell>
                                    <NumberFormat value={resultSimulation.TotalInteresCorriente || 0} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                {resultSimulation.Plazo?
                    <>
                        <br></br>
                        <Button variant="contained" onClick={downloadDetail} color="primary" disableElevation startIcon={<PrintIcon />}>
                            Descargar Detalle
                        </Button>
                    </> : null
                }
            </Grid>
        </Grid>
        <Backdrop open={loading} className={classes.backdrop}>
            <img src={process.env.PUBLIC_URL + '/car.gif'} />
        </Backdrop>
    </div>
}

export default SimulatorCR;