import Configuration from "./Configuration";

class OpaMovilService {
  constructor(base, token) {
    this.config = new Configuration(base, token, null, "api/");

    // configuration for app movil not send Bearer format
    this.config.API.defaults.headers.common["Authorization"] = `${token}`;
  }
  
  /**
   * @method consultacedulausuario consultar usuario validando cedula
   * @return {Promise} result object
   */
  async consultacedulausuario(nuevousuario) {
    return this.config.API.post(`consultacedulausuario`, { nuevousuario }).then(response => {
      if (response.status !== 200) {
        return this.config.handleResponseError(response);
      }
      return response.data;
    });
  }

  /**
   * @method validarusuario validar usuario
   * @return {Promise} result object
   */
  async validarusuario(operador, esoperador) {
    return this.config.API.post(`ValidarUsuario`, { operador, esoperador }).then(response => {
      if (response.status !== 200) {
        return this.config.handleResponseError(response);
      }
      return response.data;
    });
  }

  /**
   * @method login gestional el paso final para obterner el token
   * @return {Promise} result object
   */
  async login(auth_params) {
    return this.config.API.post(`Login`, auth_params).then(response => {
      if (response.status !== 200) {
        return this.config.handleResponseError(response);
      }
      return response.data;
    });
  }

  /**
   * @method estadodecuentas lista los estados de cuenta por tipo de producto
   * @return {Promise} result object
   */
  async estadodecuentas(params) {
    return this.config.API.post(`Estadodecuentas`, params).then(response => {
      if (response.status !== 200) {
        return this.config.handleResponseError(response);
      }
      return response.data;
    });
  }
  
  /**
   * @method estadoCuentasCompleto lista los estados de cuenta por tipo de producto
   * @return {Promise} result object
   */
  async estadoCuentasCompleto(params) {
    return this.config.API.post(`estadoCuentasCompleto`, params).then(response => {
      if (response.status !== 200) {
        return this.config.handleResponseError(response);
      }
      return response.data;
    });
  }
  
  /**
   * @method estadoCuentasCompletoPrint lista los estados de cuenta por tipo de producto
   * @return {Promise} result object
   */
  async estadoCuentasCompletoPrint(params) {
    const configBlob = { responseType: 'blob' };
    return this.config.API.post(`estadoCuentasCompletoPrint`, params, configBlob).then(response => {
      if (response.status !== 200) {
        return this.config.handleResponseError(response);
      }
      return response.data;
    });
  }

  /**
   * @method seguimientoahorros lista los estados de cuenta por tipo de producto
   * @return {Promise} result object
   */
   async seguimientoahorros(params) {
    return this.config.API.post(`seguimientoAhorro`, params).then(response => {
      if (response.status !== 200) {
        return this.config.handleResponseError(response);
      }
      return response.data;
    });
  }

  /**
   * @method seguimientoCreditos lista detalle del credito basado en su numero de pagare
   * @return {Promise} result object
   */
   async seguimientoCreditos(params) {
    return this.config.API.post(`seguimientoCredito`, params).then(response => {
      if (response.status !== 200) {
        return this.config.handleResponseError(response);
      }
      return response.data;
    });
  }
  
  /**
   * @method seguimientoNovedades lista detalle novedades
   * @return {Promise} result object
   */
  async seguimientoNovedades(params) {
    return this.config.API.post(`seguimientoNovedad`, params).then(response => {
      if (response.status !== 200) {
        return this.config.handleResponseError(response);
      }
      return response.data;
    });
  }

  /**
   * @method consultarrotativosOpaMovil lista detalle novedades
   * @return {Promise} result object
   */
   async consultarrotativosOpaMovil(params) {
    return this.config.API.post(`consultarrotativosOpaMovil`, params).then(response => {
      if (response.status !== 200) {
        return this.config.handleResponseError(response);
      }
      return response.data;
    });
  }

  /**
   * @method misCodeudores lista de codeudores
   * @return {Promise} result object
   */
   async misCodeudores(params) {
    return this.config.API.post(`misCodeudores`, params).then(response => {
      if (response.status !== 200) {
        return this.config.handleResponseError(response);
      }
      return response.data;
    });
  }

  /**
   * @method CodeudorDe lista de los creditos que se es codeudor
   * @return {Promise} result object
   */
   async CodeudorDe(params) {
    return this.config.API.post(`CodeudorDe`, params).then(response => {
      if (response.status !== 200) {
        return this.config.handleResponseError(response);
      }
      return response.data;
    });
  }

  /**
   * @method nuevousuario hace la peticion para registrar un nuevo usuario en la app
   * @return {Promise} result object
   */
   async nuevousuario(params) {
    return this.config.API.post(`nuevousuario`, params).then(response => {
      if (response.status !== 200) {
        return this.config.handleResponseError(response);
      }
      return response.data;
    });
  }

  /**
   * @method recordarusuario metodo para recordar nombre de usuario
   * @return {Promise} result object
   */
   async recordarusuario(params) {
    return this.config.API.post(`recordarusuario`, params).then(response => {
      if (response.status !== 200) {
        return this.config.handleResponseError(response);
      }
      return response.data;
    });
  }

  /**
   * @method validacionesrecuperacion metodo envia codigo de verificacion para validar si puede recuperar la clave
   * @return {Promise} result object
   */
   async validacionesrecuperacion(params) {
    return this.config.API.post(`validacionesrecuperacion`, params).then(response => {
      if (response.status !== 200) {
        return this.config.handleResponseError(response);
      }
      return response.data;
    });
  }
  
  /**
   * @method Recuperaclave metodo envia codigo de verificacion para validar si puede recuperar la clave
   * @return {Promise} result object
   */
   async Recuperaclave(params) {
    return this.config.API.post(`Recuperaclave`, params).then(response => {
      if (response.status !== 200) {
        return this.config.handleResponseError(response);
      }
      return response.data;
    });
  }

  /**
   * @method preguntasseguridad metodo envia codigo de verificacion para validar si puede recuperar la clave
   * @return {Promise} result object
   */
   async preguntasseguridad(params) {
    return this.config.API.post(`preguntasseguridad`, params).then(response => {
      if (response.status !== 200) {
        return this.config.handleResponseError(response);
      }
      return response.data;
    });
  }

  async certificados(params) {
    const configBlob = { responseType: 'blob' };
    return this.config.API.post(`certificados`, params, configBlob).then(response => {
      if (response.status !== 200) {
        return this.config.handleResponseError(response);
      }
      return response.data;
    });
  }

  async simuladorCreditosBuscarLineas() {
    return this.config.API.post(`SimuladorCreditos/BuscarLineas`).then(response => {
      if (response.status !== 200) {
        return this.config.handleResponseError(response);
      }
      return response.data;
    });
  }

  async simuladorCreditosBuscarDestinos(linea) {
    const params = {
        data: {
            objeto: {
                CodLinea: linea
            }
        }
    }

    return this.config.API.post(`SimuladorCreditos/BuscarDestinos`, params).then(response => {
      if (response.status !== 200) {
        return this.config.handleResponseError(response);
      }
      return response.data;
    });
  }

  async simuladorCreditosCalcular(params) {
    return this.config.API.post(`SimuladorCreditos/calcular`, params).then(response => {
      if (response.status !== 200) {
        return this.config.handleResponseError(response);
      }
      return response.data;
    });
  }

  async simuladorCreditosImprimirFront(params) {
    const configBlob = { responseType: 'blob' };
    return this.config.API.post(`SimuladorCreditos/ImprimirFront`, params, configBlob).then(response => {
      if (response.status !== 200) {
        return this.config.handleResponseError(response);
      }
      return response.data;
    });
  }

  async simuladorAhorrosBuscarDestinosAhorros() {
    return this.config.API.post(`SimuladorAhorros/BuscarDestinosAhorros`).then(response => {
      if (response.status !== 200) {
        return this.config.handleResponseError(response);
      }
      return response.data;
    });
  }

  async simuladorAhorrosBuscarPlazoAhorros(codeDestination) {
    const params = { data: {
        objeto: {
          Destino: codeDestination
        }
    }}

    return this.config.API.post(`SimuladorAhorros/BuscarPlazoAhorros`, params).then(response => {
      if (response.status !== 200) {
        return this.config.handleResponseError(response);
      }
      return response.data;
    });
  }

  async simuladorAhorrosComboperiodoliquida(destino, plazo) {
    const params = { data: {
        objeto: {
          Destino: destino,
          plazo: plazo
        }
    }}

    return this.config.API.post(`SimuladorAhorros/comboperiodoliquida`, params).then(response => {
      if (response.status !== 200) {
        return this.config.handleResponseError(response);
      }
      return response.data;
    });
  }

  async simuladorAhorrosCalcular(paramsObject) {
    const params = { data: {
        objeto: paramsObject
    }}

    return this.config.API.post(`SimuladorAhorros/calcular`, params).then(response => {
      if (response.status !== 200) {
        return this.config.handleResponseError(response);
      }
      return response.data;
    });
  }

  async simuladorAhorrosImprimirFront(paramsObject) {
    const params = {
        objeto: paramsObject
    }
    const configBlob = { responseType: 'blob' };
    return this.config.API.post(`SimuladorAhorros/imprimirFront`, params, configBlob).then(response => {
      if (response.status !== 200) {
        return this.config.handleResponseError(response);
      }
      return response.data;
    });
  }

  async descuentosNomina({cedula, caso, fechadevolver}) {
    const params = {
        cedula,
        caso,
        fechadevolver
    }

    return this.config.API.post(`descuentosNomina`, params).then(response => {
      if (response.status !== 200) {
        return this.config.handleResponseError(response);
      }
      return response.data;
    });
  }

  async origenAhorros(params) {

    return this.config.API.post(`Origenahorros`, params).then(response => {
      if (response.status !== 200) {
        return this.config.handleResponseError(response);
      }
      return response.data;
    });
  }
}

export default OpaMovilService;
