import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { CompanyContext } from '../../store';

import AccountType, { EAccountType } from './AccountType';
import CoSigner, { ECoSignerType } from '../CoSigner';
import OpaMovilService from '../../api/OpaMovilService';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import * as CryptoJS from 'crypto-js';
import Alert from '@material-ui/core/Alert';

import PrintIcon from '@material-ui/icons/Print';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import Modal from '../../components/Modal';
import CreditosDigitales from '../../components/CreditosDigitales';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    headerPanel: {
        backgroundColor: theme.palette.primary,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    table: {},
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        background: 'linear-gradient(75deg, rgba(255, 255, 255, 0.48) 0%, rgb(255, 255, 255) 100%)'
    },
}));



const Accounts = () => {
    const classes = useStyles();
    const [panel1, setPanel1] = useState(true)
    const [panel2, setPanel2] = useState(true)
    const [panel3, setPanel3] = useState(true)
    const [panel4, setPanel4] = useState(true)
    const [panel5, setPanel5] = useState(true)
    const [panel6, setPanel6] = useState(true)

    const theme = useContext(CompanyContext);
    const _secret = process.env.REACT_APP_OPAMOVIL_SECRET || ""

    const [AHORRO, setAHORRO] = useState([]);
    const [CREDIT, setCREDIT] = useState([]);
    const [ESTDEB, setESTDEB] = useState([]);
    const [ESTNOV, setESTNOV] = useState([]);
    const [loading, setLoading] = useState(false);

    
    const [openModal, setOpenModal] = useState(0)
    const [urlPage, setUrlPage] = useState({})
    const [loadingPDF, setLoadingPDF] = useState(false);

    let params = {
        id: CryptoJS.AES.encrypt(localStorage.getItem("opa:movil:id") || "", _secret).toString(),
        CedulaAsociado: CryptoJS.AES.encrypt(localStorage.getItem("opa:movil:cedula") || "", _secret).toString(),
        operador: CryptoJS.AES.encrypt(localStorage.getItem("opa:movil:cedula") || "", _secret).toString(),
        Tipo:'',
        pagare: 0,
        RegistroEmpezar: 0,
        RegistroMostrar: 10
    }

    const listProducts = async () => {
        const token = sessionStorage.getItem("opa:movil:jwt")
        const api = new OpaMovilService(theme.api_url, token);
        setLoading(true)
        try {
            params.Tipo = 'ALL'

            const response = await api.estadoCuentasCompleto(params);

            setAHORRO(response.AHORRO);
            sessionStorage.setItem('opa:movil:products:' + EAccountType.AHORROS, JSON.stringify(response.AHORRO))

            setCREDIT(response.CREDIT);
            sessionStorage.setItem('opa:movil:products:' + EAccountType.CREDITOS, JSON.stringify(response.CREDIT))

            setESTDEB(response.ESTDEB);
            sessionStorage.setItem('opa:movil:products:' + EAccountType.DEBITOS, JSON.stringify(response.ESTDEB))

            setESTNOV(response.ESTNOV);
            sessionStorage.setItem('opa:movil:products:' + EAccountType.NOVEDADES, JSON.stringify(response.ESTNOV))
            
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }

    useEffect(() => {
        listProducts()
        // eslint-disable-next-line
    }, [])

    const downloadDetail = async () => {
        const token = sessionStorage.getItem("opa:movil:jwt")
        const api = new OpaMovilService(theme.api_url, token);
        setLoadingPDF(true)
        try {
            params.Tipo = 'ALL'
            const responseBuffer = await api.estadoCuentasCompletoPrint(params);

            const blobPDF = new Blob([responseBuffer], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(blobPDF);
            
            setOpenModal(Math.random())
            setUrlPage(fileURL)
        } catch (e) {
            console.log(e)
        }
        setLoadingPDF(false)
    }

    return <div className={classes.root}>
        <CreditosDigitales />
        <Modal status={openModal} documentUrl={urlPage} />
        {loading? <>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    style={{height: '100vh'}}
                >
                    <Grid item xs={3}>
                        <img src={process.env.PUBLIC_URL + '/car.gif'} />
                    </Grid>   
                </Grid>
            </> : <>
            {AHORRO.length?
                <Grid item xs={12} md={12}>
                    <Card>
                        <Accordion expanded={panel1} onChange={() => setPanel1(!panel1)}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="ah-panel-content"
                                id="ah-panel-header"
                                style={{backgroundColor: 'rgb(230, 230, 230)'}}
                            >
                                <Typography className={classes.heading}>Aportes y Ahorros</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <AccountType type={EAccountType.AHORROS} title=""  data={AHORRO} />
                            </AccordionDetails>
                        </Accordion>
                    </Card>
                </Grid>
                :
                <Alert severity="info">No tiene Aportes y Ahorros</Alert>
            }
            <br></br>
            {CREDIT.length?
                <Grid item xs={12} md={12}>
                    <Card>
                        <Accordion expanded={panel2} onChange={() => setPanel2(!panel2)}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="cr-panel-content"
                                id="cr-panel-header"
                                style={{backgroundColor: 'rgb(230, 230, 230)'}}
                            >
                                <Typography className={classes.heading}>Creditos</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <AccountType type={EAccountType.CREDITOS} title=""  data={CREDIT} />
                            </AccordionDetails>
                        </Accordion>
                    </Card>
                </Grid>
                :
                <Alert severity="info">No tiene Creditos</Alert>
            }
            <br></br>
            {ESTNOV.length?
                <Grid item xs={12} md={12}>
                    <Card>
                        <Accordion expanded={panel3} onChange={() => setPanel3(!panel3)}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="no-panel-content"
                                id="no-panel-header"
                                style={{backgroundColor: 'rgb(230, 230, 230)'}}
                            >
                                <Typography className={classes.heading}>Novedades</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <AccountType type={EAccountType.NOVEDADES} data={ESTNOV} />
                            </AccordionDetails>
                        </Accordion>
                    </Card>
                </Grid>
                :
                <Alert severity="info">No tiene Novedades</Alert>
            }
            <br></br>
            {ESTDEB.length?
                <Grid item xs={12} md={12}>
                    <Card>
                        <Accordion expanded={panel4} onChange={() => setPanel4(!panel4)}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="db-panel-content"
                                id="db-panel-header"
                                style={{backgroundColor: 'rgb(230, 230, 230)'}}
                            >
                                <Typography className={classes.heading}>Debitos</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <AccountType type={EAccountType.DEBITOS}  data={ESTDEB} />
                            </AccordionDetails>
                        </Accordion>
                    </Card>
                </Grid>
                :
                <Alert severity="info">No tiene Debitos</Alert>
            }
            <br></br>
            
            <Grid item xs={12} md={12}>
                <Card>
                    <Accordion expanded={panel5} onChange={() => setPanel5(!panel5)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="mc-panel-content"
                            id="mc-panel-header"
                            style={{backgroundColor: 'rgb(230, 230, 230)'}}
                        >
                            <Typography className={classes.heading}>Mis Codeudores</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <CoSigner type={ECoSignerType.CODEUDORES} />
                        </AccordionDetails>
                    </Accordion>
                </Card>
            </Grid>
            <br></br>
            <Grid item xs={12} md={12}>
                <Card>
                    <Accordion expanded={panel6} onChange={() => setPanel6(!panel6)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="cd-panel-content"
                            id="cd-panel-header"
                            style={{backgroundColor: 'rgb(230, 230, 230)'}}
                        >
                            <Typography className={classes.heading}>Codeudor de</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <CoSigner type={ECoSignerType.CODEUDORDE} />
                        </AccordionDetails>
                    </Accordion>
                </Card>
            </Grid>
            <br></br>
            <Button variant="contained" onClick={downloadDetail} color="primary" disableElevation startIcon={<PrintIcon />}>
                Descargar Detalle
            </Button>
            <br></br>
            <Backdrop open={loadingPDF} className={classes.backdrop}>
                <img src={process.env.PUBLIC_URL + '/car.gif'} />
            </Backdrop>
            <br></br>
        </>}
    </div>
}


export default Accounts;