import React, { useEffect, useContext, useState } from 'react';
import { CompanyContext } from '../../../store';
import {
    useParams,
    useNavigate,
    useLocation,
    Link
} from "react-router-dom";
import { EAccountType } from '../AccountType';
import OpaMovilService from '../../../api/OpaMovilService';
import * as CryptoJS from 'crypto-js';

import { createStyles, Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';

import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';
import Load from "../../../features/load";
import NumberFormat from 'react-number-format';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    boxShadow: 24,
    p: 4,
    bgcolor: 'background.paper',
    border: '1px solid rgba(0,0,0,0.2)',
    borderRadius: '6px',
    boxShadow: '0 3px 9px rgba(0,0,0,0.5)'
};

interface paramsACounts {
    line: string,
    productId: string,
    productType: string,
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            padding: '15px',
            backgroundColor: theme.palette.background.paper,
        },
        chip: {
            margin: theme.spacing(0.5),
        },
        section1: {
            margin: theme.spacing(3, 2),
        },
        section2: {
            margin: theme.spacing(2),
        },
        section3: {
            margin: theme.spacing(3, 1, 1),
        },
        paper: {
            textAlign: 'left',
            color: theme.palette.text.secondary,
        },
    }),
);

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

interface IDataProductTypeProps {
    name: string;
    type: string;
    account: string;
    details: any;
    product: any;
    loadingProduct: boolean;
}

function mezclarYSumarPorDocumento(data: any) {
    const agrupados = {};

    // Agrupar por Documento y sumar los campos especificados
    data[0].forEach(item => {
        const doc = item.Documento.trim();
        if (!agrupados[doc]) {
            agrupados[doc] = {
                ...item,
                AbonoCapital: parseFloat(item.AbonoCapital.replace(/,/g, '')),
                AbonoCosto: parseFloat(item.AbonoCosto.replace(/,/g, '')),
                "AbonoInterés": parseFloat(item["AbonoInterés"].replace(/,/g, '')),
                ConsignacionTotal: parseFloat(item.ConsignacionTotal.replace(/,/g, '')),
                "InterésMora": parseFloat(item["InterésMora"].replace(/,/g, '')),
                ValorTransaccion: parseFloat(item.ValorTransaccion.replace(/,/g, ''))
            };
        } else {
            agrupados[doc].AbonoCapital += parseFloat(item.AbonoCapital.replace(/,/g, ''));
            agrupados[doc].AbonoCosto += parseFloat(item.AbonoCosto.replace(/,/g, ''));
            agrupados[doc]["AbonoInterés"] += parseFloat(item["AbonoInterés"].replace(/,/g, ''));
            agrupados[doc].ConsignacionTotal += parseFloat(item.ConsignacionTotal.replace(/,/g, ''));
            agrupados[doc]["InterésMora"] += parseFloat(item["InterésMora"].replace(/,/g, ''));
            agrupados[doc].ValorTransaccion += parseFloat(item.ValorTransaccion.replace(/,/g, ''));
        }
    });

    // Convertir los valores sumados nuevamente a strings con formato correcto
    return Object.values(agrupados).map(item => ({
        ...item,
        AbonoCapital: item.AbonoCapital.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}),
        AbonoCosto: item.AbonoCosto.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}),
        "AbonoInterés": item["AbonoInterés"].toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}),
        ConsignacionTotal: item.ConsignacionTotal.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}),
        "InterésMora": item["InterésMora"].toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}),
        ValorTransaccion: item.ValorTransaccion.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})
    }));
}

const Detail = () => {
    const theme = useContext(CompanyContext);
    const _secret = process.env.REACT_APP_OPAMOVIL_SECRET || ""

    const [details, setDetails] = useState([]);
    const [product, setProduct] = useState<any>();
    const [loadingProduct, setLoadingProduct] = useState<boolean>(false);
    const [name, setName] = useState('');

    let { productId, productType }: paramsACounts = useParams();
    let query = useQuery();

    let params: any = {}

    useEffect(() => {
        // decode product type in storage
        if (!productId || !productType) return;
        // find session
        let productsSessionEncoded = sessionStorage.getItem('opa:movil:products:' + productType)

        if (query.get("type") === 'ROTA') {
            productsSessionEncoded = sessionStorage.getItem('opa:movil:products:ROTA')
        }

        const productsSessionDecoded = JSON.parse(productsSessionEncoded || '[]');
        const findProduct = productsSessionDecoded.find((item: any) => {
            switch (productType) {
                case EAccountType.AHORROS:
                    return item.NumeroCuentaAhorros.toString().trim() === productId.toString().trim() &&
                        item.Tipo.toString().trim() === (query.get("type") || '').toString().trim()
                case EAccountType.CREDITOS:
                    return item.Pagare.toString().trim() === productId.toString().trim()
                case EAccountType.NOVEDADES:
                    return item.codnovedad.toString().trim() === productId.toString().trim() &&
                        item.NumeroNovedad.toString().trim() === (query.get("type") || '').toString().trim()
                case EAccountType.DEBITOS:
                    return item.NumeroCuentaAhorros.toString().trim() === productId.toString().trim()
                default:
                    return false;
            }
        })

        setProduct(findProduct)
        // eslint-disable-next-line
    }, [productId, productType])

    useEffect(() => {
        if (!product) return;

        switch (productType) {
            case EAccountType.AHORROS:
                setName("Ahorros")
                findDetail('AHORRO')
                break;
            case EAccountType.CREDITOS:
                setName("Creditos")
                findDetail('CREDIT')
                break;
            case EAccountType.NOVEDADES:
                setName("Novedades")
                findDetail('ESTNOV')
                break;
            case EAccountType.DEBITOS:
                setName("Debitos")
                findDetail('ESTDEB')
                break;
        }
        // eslint-disable-next-line
    }, [product])

    const findDetail = async (type: string) => {
        const token = sessionStorage.getItem("opa:movil:jwt")
        const api = new OpaMovilService(theme.api_url, token);
        setLoadingProduct(true)
        try {
            const response = await parseProductParams(query.get("type") || type, api)
            setDetails(response[0] || [])
        } catch (error) {
            console.log(error)
        }
        setLoadingProduct(false)
    }

    const parseProductParams = (type: string, api: OpaMovilService) => {
        if (productType === EAccountType.AHORROS) {
            params = {
                CedulaAsociado: CryptoJS.AES.encrypt(localStorage.getItem("opa:movil:cedula") || "", _secret).toString(),
                operador: CryptoJS.AES.encrypt(localStorage.getItem("opa:movil:cedula") || "", _secret).toString(),
                esoperador: 'N',
                FechaFinal: new Date(),
                FechaInicial: 19900101,
                Linea: CryptoJS.AES.encrypt(product['Linea'], _secret).toString(),
                NumeroCuenta: CryptoJS.AES.encrypt(productId, _secret).toString(),
                RegistroEmpezar: 0,
                RegistroMostrar: 100,
                Tipo: CryptoJS.AES.encrypt(type, _secret).toString()
            }

            return api.seguimientoahorros(params)
        } else if (productType === EAccountType.CREDITOS) {
            params = {
                esoperador: 'N',
                FechaFinal: new Date(),
                FechaInicial: 19900101,
                operador: CryptoJS.AES.encrypt(localStorage.getItem("opa:movil:cedula") || "", _secret).toString(),
                RegistroEmpezar: 0,
                RegistroMostrar: 100,
                Pagare: CryptoJS.AES.encrypt(productId, _secret).toString()
            }

            if (product['esrotativo'] === "S") {
                params = {
                    CedulaAsociado: CryptoJS.AES.encrypt(localStorage.getItem("opa:movil:cedula") || "", _secret).toString(),
                    operador: CryptoJS.AES.encrypt(localStorage.getItem("opa:movil:cedula") || "", _secret).toString(),
                    Tipo: CryptoJS.AES.encrypt('ROTA', _secret).toString(),
                    pagare: CryptoJS.AES.encrypt(productId, _secret).toString(),
                    RegistroEmpezar: 0,
                    RegistroMostrar: 100
                }

                return api.estadodecuentas(params).then(response => {
                    sessionStorage.setItem('opa:movil:products:ROTA', JSON.stringify(response[0]))
                    return response
                })
            } else {
                return api.seguimientoCreditos(params).then( res => {
                    const resultados = mezclarYSumarPorDocumento(res);
                    return [resultados];
                })
            }

        } else if (productType === EAccountType.NOVEDADES) {
            params = {
                cedulasociado: CryptoJS.AES.encrypt(localStorage.getItem("opa:movil:cedula") || "", _secret).toString(),
                esoperador: 'N',
                FechaFinal: new Date(),
                FechaInicial: 19900101,
                Linea: CryptoJS.AES.encrypt(product['codnovedad'], _secret).toString(),
                operador: CryptoJS.AES.encrypt(localStorage.getItem("opa:movil:cedula") || "", _secret).toString(),
                RegistroEmpezar: 0,
                RegistroMostrar: 100
            }

            return api.seguimientoNovedades(params)
        }

        return new Promise((resolve) => resolve([[]]));
    }

    return <>
        {product ?
            <DataProduct name={name} product={product} account={productId} type={productType} details={details} loadingProduct={loadingProduct} /> : null
        }
    </>
}

export function DataProduct({ name, type, account, details, product, loadingProduct }: IDataProductTypeProps) {
    const classes = useStyles();

    const navigate = useNavigate();

    const [detailAccount, setDetailAccount] = useState<any>({});

    useEffect(() => {
        setDetailAccount(product)
    }, [product])

    const goBack = () => navigate(-1);

    return (
        <Card className={classes.root}>
            <div className={classes.section1}>
                <Grid container alignItems="center">
                    <Grid item xs>
                        <Typography gutterBottom variant="h4">
                            <IconButton aria-label="delete" onClick={goBack}>
                                <ArrowBackIcon />
                            </IconButton>
                            {name}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography gutterBottom variant="h6">
                            {detailAccount['SaldoTotalCuenta']}
                        </Typography>
                    </Grid>
                </Grid>
                <Typography color="textSecondary" variant="body2"></Typography>
            </div>
            <Divider variant="middle" />
            <div className={classes.section2}>
                {type === EAccountType.AHORROS ?
                    <HeaderAhorros detailAccount={detailAccount} /> :
                    type === EAccountType.CREDITOS ?
                        <HeaderCreditos detailAccount={detailAccount} /> :
                        type === EAccountType.NOVEDADES ?
                            <HeaderNovedades detailAccount={detailAccount} /> :
                            type === EAccountType.DEBITOS ?
                                <HeaderDebitos detailAccount={detailAccount} /> : null
                }
            </div>
            <Divider variant="middle" />
            {loadingProduct ?
                <Load /> :
                <TableContainer>
                    {type === EAccountType.AHORROS ?
                        <TableAhorros details={details} /> :
                        type === EAccountType.CREDITOS ?
                            <TableCreditos details={details} esrotativo={detailAccount ? detailAccount['esrotativo'] === 'S' ? true : false : false} /> :
                            type === EAccountType.NOVEDADES ?
                                <TableNovedades details={details} /> :
                                type === EAccountType.DEBITOS ?
                                    null : null
                    }
                </TableContainer>
            }
        </Card>
    );
}

const HeaderAhorros = ({ detailAccount }: { detailAccount: any }) => {
    const classes = useStyles();

    return <>
        <Grid container spacing={3}>
            <Grid item xs={6} className={classes.paper}>
                Cuenta: {detailAccount['NumeroCuentaAhorros']}
            </Grid>
            <Grid item xs={6} className={classes.paper}>
                Tipo: {detailAccount['Tipo']}
            </Grid>

            <Grid item xs={6} className={classes.paper}>
                Interes Causado Cuenta: {detailAccount['InteresCausadoCuenta']}
            </Grid>
            <Grid item xs={6} className={classes.paper}>
                Interes Disponible Cuenta: {detailAccount['InteresDisponibleCuenta']}
            </Grid>

            <Grid item xs={6} className={classes.paper}>
                Retencion Fuente Acumulada: {detailAccount['RetencionFuenteAcumulada']}
            </Grid>
            <Grid item xs={6} className={classes.paper}>
                Valor Cuota Ahorro: {detailAccount['ValorCuotaAhorro']}
            </Grid>

            <Grid item xs={6} className={classes.paper}>
                Agencia: {detailAccount['agencia']}
            </Grid>
            <Grid item xs={6} className={classes.paper}>
                Ultima Transaccion: {moment.utc(detailAccount['ultimaFechaTransaccion']).format('YYYY-MM-DD')}
            </Grid>

            <Grid item xs={6} className={classes.paper}>
                Fecha Apertura: {moment.utc(detailAccount['FechaAperturaCuenta']).format('YYYY-MM-DD')}
            </Grid>
            <Grid item xs={6} className={classes.paper}>
                Fecha Vencimiento: {moment.utc(detailAccount['FechaVenceCuenta']).format('YYYY-MM-DD')}
            </Grid>
        </Grid>
    </>
}

const HeaderCreditos = ({ detailAccount }: { detailAccount: any }) => {
    const classes = useStyles();

    return <>
        <Grid container spacing={3}>
            <Grid item xs={6} className={classes.paper}>
                Destino: {detailAccount['nombredestino']}
            </Grid>
            <Grid item xs={6} className={classes.paper}>
                Pagare: {detailAccount['Pagare']}
            </Grid>

            {detailAccount['esrotativo'] === 'S' ?
                <>
                    <Grid item xs={6} className={classes.paper}>
                        Cupo: {detailAccount['cupo']}
                    </Grid>
                    <Grid item xs={6} className={classes.paper}>
                        Cupo Disponible: {detailAccount['disponible']}
                    </Grid>
                </>
                :
                <>
                    <Grid item xs={6} className={classes.paper}>
                        Línea: {detailAccount['CodigoLinea']}
                    </Grid>
                    <Grid item xs={6} className={classes.paper}>
                        Destino: {detailAccount['CodigoDestino']}
                    </Grid>
                </>
            }

            <Grid item xs={6} className={classes.paper}>
                Saldo Ponerse Al Día: {detailAccount['SaldoPonerseDia']}
            </Grid>
            <Grid item xs={6} className={classes.paper}>
                Total Deuda: {detailAccount['SaldoCapital']}
            </Grid>


            <Grid item xs={6} className={classes.paper}>
                Valor Cuota : {detailAccount['Anualidad']}
            </Grid>
            {detailAccount['esrotativo'] === 'S' ?
                <>
                    <Grid item xs={6} className={classes.paper}>
                        Dia Corte: {detailAccount['diaCorte']}
                    </Grid>
                </>
                :
                <>
                    <Grid item xs={6} className={classes.paper}>
                        F.Venc.Cuota: {moment.utc(detailAccount['FechaVenceCuota']).format('YYYY-MM-DD')}
                    </Grid>
                </>
            }
        </Grid>
    </>
}

const HeaderNovedades = ({ detailAccount }: { detailAccount: any }) => {
    const classes = useStyles();

    return <>
        <Grid container spacing={3}>
            <Grid item xs={6} className={classes.paper}>
                Nombre: {detailAccount['NombreNovedad']}
            </Grid>
            <Grid item xs={6} className={classes.paper}>
                Saldo: {detailAccount['SaldoNovedad']}
            </Grid>

            <Grid item xs={6} className={classes.paper}>
                Cuota: {detailAccount['CuotaNovedad']}
            </Grid>
            <Grid item xs={6} className={classes.paper}>
                Nro Cuotas Mora: {detailAccount['NroCuotasMoraNovedad']}
            </Grid>

            <Grid item xs={12} className={classes.paper}>
                Paga Hasta: {moment.utc(detailAccount['NovedadPagadaHasta']).format('YYYY-MM-DD')}
            </Grid>
        </Grid>
    </>
}

const HeaderDebitos = ({ detailAccount }: { detailAccount: any }) => {
    const classes = useStyles();

    return <>
        <Grid container spacing={3}>
            <Grid item xs={6} className={classes.paper}>
                Valor: {detailAccount['ValorADebitar']}
            </Grid>
            <Grid item xs={6} className={classes.paper}>
                Pagare Destino: {detailAccount['PagareAPagar']}
            </Grid>


            <Grid item xs={12}>
                <Typography>Cuenta Débito</Typography>
            </Grid>

            <Grid item xs={6} className={classes.paper}>
                Nombre: {detailAccount['NombreLineaADebitar']}
            </Grid>
            <Grid item xs={6} className={classes.paper}>
                Número Cuenta: {detailAccount['CuentaADebitar']}
            </Grid>

            <Grid item xs={6} className={classes.paper}>
                Linea: {detailAccount['CodigoLineaADebitar']}
            </Grid>
            <Grid item xs={6} className={classes.paper}>
                Día Débito: {detailAccount['DiaDelDebito']}
            </Grid>

            <Grid item xs={6} className={classes.paper}>
                Fecha Vencimiento: {moment.utc(detailAccount['fechavencimiento']).format('YYYY-MM-DD')}
            </Grid>
        </Grid>
    </>
}


const TableAhorros = ({ details }: { details: any }) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return <>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div style={{ width: '100%' }}>
                    <p style={{ fontSize: 25 }}>Detalle del documento ## realizado en ##fecha##</p>
                    <br></br>
                    <hr></hr>
                    <Table size="small" aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell><strong>Descripción</strong></TableCell>
                                <TableCell>Total Efectivo</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell><strong>Consignación</strong></TableCell>
                                <TableCell>###########</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><strong>Abono Capital</strong></TableCell>
                                <TableCell>###########</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><strong>Abono Interes Corriente</strong></TableCell>
                                <TableCell>###########</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <br></br>
                </div>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'flex-end' }}>
                    <Button variant="contained" color="primary" onClick={handleClose}>Cerrar</Button>
                </div>
            </Box>
        </Modal>
        <Table size="small" aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell>Fecha</TableCell>
                    <TableCell>Documento</TableCell>
                    <TableCell>Valor</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {details.map((detail: any, key: number) =>
                    <TableRow key={key}>
                        <TableCell>{detail['fechaTrabajo']}</TableCell>
                        <TableCell>{detail['Documento']}</TableCell>
                        <TableCell align="right">
                            {detail['positivo'] === '0' ? '- ' : null}
                            {detail['ValorTransaccion']}
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    </>
}

const TableCreditos = ({ details, esrotativo }: { details: any, esrotativo: boolean }) => {

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [movimiento, setMovimiento] = useState();

    return <>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div style={{ width: '100%' }}>
                    {movimiento ? <p style={{ fontSize: 25 }}>Detalle del documento {movimiento['Documento']} realizado en {movimiento['fechaTrabajo']}</p>: null}
                    
                    <br></br>
                    <hr></hr>
                    {movimiento ? 
                        <Table size="small" aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell><strong>Descripción</strong></TableCell>
                                    <TableCell>Total Efectivo</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell><strong>Consignación</strong></TableCell>
                                    <TableCell style={{textAlign: 'right'}}>{movimiento['ConsignacionTotal']}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Abono Capital</strong></TableCell>
                                    <TableCell style={{textAlign: 'right'}}> {movimiento['AbonoCapital']} </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Abono Interes Corriente</strong></TableCell>
                                    <TableCell style={{textAlign: 'right'}}> {movimiento['AbonoInterés']} </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Abono Costos</strong></TableCell>
                                    <TableCell style={{textAlign: 'right'}}> {movimiento['AbonoCosto']} </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table> : null
                    }
                    <br></br>
                </div>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'flex-end' }}>
                    <Button variant="contained" color="primary" onClick={handleClose}>Cerrar</Button>
                </div>
            </Box>
        </Modal>
        <Table size="small" aria-label="simple table">
            {esrotativo ?
                <>
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Concepto</TableCell>
                            <TableCell>Pagare</TableCell>
                            <TableCell>Fecha Apertura</TableCell>
                            {/* <TableCell>Plazo</TableCell> */}
                            <TableCell>Fecha Vencimiento</TableCell>
                            <TableCell>Cuota</TableCell>
                            <TableCell>Saldo</TableCell>
                            {/* <TableCell>%Cump</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {details.map((detail: any, index: number) =>
                            <TableRow key={index}>
                                <TableCell>{detail['cuantos']}</TableCell>
                                <TableCell>{detail['CodigoDestino']}</TableCell>
                                <TableCell>
                                    <Link to={`/home/account/${EAccountType.CREDITOS}/${detail['Pagare']}?type=ROTA`}>{detail['Pagare']}</Link>
                                </TableCell>
                                <TableCell>{detail['F_iniciofinanciacion']}</TableCell>
                                {/* <TableCell>sss</TableCell> */}
                                <TableCell>{detail['F_venceCR']}</TableCell>
                                <TableCell>{detail['Anualidad']}</TableCell>
                                <TableCell>{detail['SaldoCapital']}</TableCell>
                                {/* <TableCell>
                  0
                </TableCell> */}
                            </TableRow>
                        )}
                    </TableBody>
                </>
                :
                <>
                    <TableHead>
                        <TableRow>
                            <TableCell>Fecha</TableCell>
                            <TableCell>Documento</TableCell>
                            <TableCell>Valor</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {details.map((detail: any, index: number) =>
                            <TableRow key={index}>
                                <TableCell>{detail['fechaTrabajo']}</TableCell>
                                <TableCell>{detail['Documento']}</TableCell>
                                <TableCell align="right">
                                    {detail['ValorTransaccion'] === '0.00' ? '+ ' + detail['ConsignacionTotal'] : '- ' + detail['ValorTransaccion']}
                                </TableCell>

                                <TableCell align="right">
                                    <IconButton color="primary" size="small" onClick={() => {
                                        setMovimiento(detail)
                                        handleOpen()
                                    }}>
                                        <SearchIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </>
            }

        </Table>
    </>
}

const TableNovedades = ({ details }: { details: any }) => {
    return <>
        <Table size="small" aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell>Fecha</TableCell>
                    <TableCell>Documento</TableCell>
                    <TableCell>Valor</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {details.map((detail: any, key: number) =>
                    <TableRow key={key}>
                        <TableCell>{detail['fechapago']}</TableCell>
                        <TableCell>{detail['documento']}</TableCell>
                        <TableCell align="right">
                            {detail['positivo'] === '0' ? '- ' : null}
                            <NumberFormat value={detail['valor']} displayType={'text'} thousandSeparator={true} />
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    </>
}

export default Detail;