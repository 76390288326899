import LoginBase from './LoginBase';
import { OpaMovilRecoverPassword } from '../../features/login/opa/OpaMovilRecoverPassword';


// ----------------------------------------------------------------------

export default function Login() {
  return (
    <LoginBase>
        <OpaMovilRecoverPassword />
    </LoginBase>
  );
}