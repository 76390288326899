import PropTypes from 'prop-types';
// material
import { Box } from '@material-ui/core';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx, url }) {
  return <Box component="img" src={url} sx={{ width: 100, height: 100, ...sx }} />;
}
