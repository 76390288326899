import { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 820,
  boxShadow: 24,
  p: 4,
  bgcolor: 'background.paper',
  border: '1px solid rgba(0,0,0,0.2)',
  borderRadius: '6px',
  boxShadow: '0 3px 9px rgba(0,0,0,0.5)',
  height: 'calc(100vh - 100px)'
};

export default function BasicModal({status, documentUrl}) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if(status) handleOpen();
    if(!status) handleClose();
  }, [status])

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <object
                type="application/pdf"
                data={documentUrl}
                style={{width: '100%', height: 'calc(100vh - 200px)'}}
            >
            </object>
            <div style={{width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'flex-end'}}>
                <Button variant="contained" color="primary" onClick={handleClose}>Cerrar</Button>
            </div>
        </Box>
      </Modal>
    </div>
  );
}
