import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(({ theme }) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }
}));

const Profile = () => {
    let navigate = useNavigate();
    const classes = useStyles();

    return <div className={classes.root}>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Paper className={classes.paper}> <h1>Perfil</h1> </Paper>
            </Grid>

            <Grid item xs={12} md={4}>
                <Paper className={classes.paper}>
                    <span>Cuenta</span>
                    <br></br><br></br>
                    <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={() => navigate("/home/profile/change-password")}
                    >
                        Cambiar Clave
                    </Button>
                </Paper>
            </Grid>
        </Grid>
    </div>
}

export default Profile;