import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { CompanyContext } from '../../store';
import OpaMovilService from '../../api/OpaMovilService';
import * as CryptoJS from 'crypto-js';
import Card from '@material-ui/core/Card';
import { Icon } from '@iconify/react';
import certificateSolid from '@iconify/icons-teenyicons/certificate-solid';
import certificateSolidAlerted from '@iconify/icons-clarity/certificate-solid-alerted';
import moneyCheckAlt from '@iconify/icons-fa-solid/money-check-alt';
import Modal from '../../components/Modal';

import Backdrop from '@material-ui/core/Backdrop';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    containerFormInLine: {
        display: 'flex',
        textAlign: 'center',
        alignContent: 'center',
        justifyContent: 'center',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        background: 'linear-gradient(75deg, rgba(255, 255, 255, 0.48) 0%, rgb(255, 255, 255) 100%)'
    },
}));

enum TypesCertificates {
    AFILIA = "AFILIA",
    AFIDEU = "AFIDEU",
    INGRET = "INGRET",
}

const Certificates = () => {
    const theme = useContext(CompanyContext);
    const _secret = process.env.REACT_APP_OPAMOVIL_SECRET || ""
    const classes = useStyles();

    const [loading, setLoading] = useState(false)

    const years = Array.from(new Array(5), (_, index) => (new Date()).getFullYear() - (index + 1))
    const [year, setYear] = useState((new Date()).getFullYear() - 1); // DEFAULT PASS YEAR
    
    const [openModal, setOpenModal] = useState(0)
    const [urlPage, setUrlPage] = useState({})

    const baseParams: {
        CedulaAsociado: string,
        Tipo: TypesCertificates,
        ano?: number
    } = {
        CedulaAsociado: CryptoJS.AES.encrypt(localStorage.getItem("opa:movil:id") || "", _secret).toString(),
        Tipo: TypesCertificates.AFILIA,
    }


    const handleChange = (event: any) => {
        setYear(event.target.value);
    };

    const getCertificate = async (typeCertificate: TypesCertificates) => {
        setLoading(true)
        const params = Object.assign({}, baseParams);

        params.Tipo = typeCertificate;

        const token = sessionStorage.getItem("opa:movil:jwt");
        const api = new OpaMovilService(theme.api_url, token);

        // add param year
        if (typeCertificate === TypesCertificates.INGRET) {
            params.ano = Number(year);
        }


        try {
            const responseBuffer = await api.certificados(params);

            const blobPDF = new Blob([responseBuffer], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(blobPDF);
            // window.open(fileURL);

            
            setOpenModal(Math.random())
            setUrlPage(fileURL)

        } catch (error) {
            console.log(error)
        }

        setLoading(false)
    }


    return <div className={classes.root}>
        <Modal status={openModal} documentUrl={urlPage} />
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Paper className={classes.paper}> <h1>Certificados</h1> </Paper>
            </Grid>

            <Grid item xs={12} sm={4}>
                <Card>
                    <Paper className={classes.paper}>
                        <Icon icon={certificateSolidAlerted} width="45" />
                        <br></br>
                        <span>Afiliación</span>
                        <br></br>
                        <br></br>
                        <Button variant="contained" color="primary" disableElevation onClick={() => getCertificate(TypesCertificates.AFILIA)} >
                            Descargar
                        </Button>
                    </Paper>
                </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Card>
                    <Paper className={classes.paper}>
                        <Icon icon={moneyCheckAlt} width="45" />
                        <br></br>
                        <span>Deudas</span>
                        <br></br>
                        <br></br>
                        <Button variant="contained" color="primary" disableElevation onClick={() => getCertificate(TypesCertificates.AFIDEU)}>
                            Descargar
                        </Button>
                    </Paper>
                </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Card>
                    <Paper className={classes.paper}>
                        <Icon icon={certificateSolid} width="45" />
                        <br></br>
                        <span>Ingresos y Retención</span>
                        <br></br>
                        <br></br>
                        <div className={classes.containerFormInLine}>
                            <select onChange={handleChange}>
                                {years.map(item => {
                                    return <option key={`year${item}`} value={item}>{item}</option>
                                })}
                            </select>
                            &nbsp;&nbsp;
                            <Button variant="contained" color="primary" disableElevation onClick={() => getCertificate(TypesCertificates.INGRET)}>
                                Descargar
                            </Button>
                        </div>
                    </Paper>
                </Card>
            </Grid>
        </Grid>

        <Backdrop open={loading} className={classes.backdrop}>
            <img src={process.env.PUBLIC_URL + '/car.gif'} />
        </Backdrop>
    </div>
}

export default Certificates;