import React, { useContext, useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import { CompanyContext } from '../../../store';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import OpaMovilService from '../../../api/OpaMovilService';
import * as CryptoJS from 'crypto-js';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useAuth } from '../../../auth/ProvideAuth';
import { useNavigate, Link as LinkRouter } from "react-router-dom";
import Modal from '@material-ui/core/Modal';
import { Icon } from '@iconify/react';
import lockFill from '@iconify/icons-eva/lock-fill';

export function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://opa.com.co/">
                OPA S.A.S
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    boxShadow: 24,
    textAlign: 'justify',
    p: 4,
    bgcolor: 'background.paper',
    border: '1px solid rgba(0,0,0,0.2)',
    borderRadius: '6px',
    boxShadow: '0 3px 9px rgba(0,0,0,0.5)',
    height: '500px'
};

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme['palette'].secondary.main,
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    security_image: {
        objectFit: 'contain'
    }
}));

export function OpaMovil() {
    const theme = useContext(CompanyContext);
    let navigate = useNavigate();
    let auth = useAuth();

    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const [cedula, setCedula] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [imagenSeguridad, setImagenSeguridad] = useState("");
    const [isCode, setIsCode] = useState(false);
    const [codigoSeguridad, setCodigoSeguridad] = useState("");

    const [checked, setChecked] = useState(false)

    const [loadingStep0, setLoadingStep0] = useState(false);

    const _secret = process.env.REACT_APP_OPAMOVIL_SECRET || ""

    const getSecurityInfo = async (cedula_result_string: string) => {
        const api = new OpaMovilService(theme.api_url);
        try {
            const cedula_encrypt = CryptoJS.AES.encrypt(cedula_result_string, _secret).toString()

            const response = await api.validarusuario(cedula_encrypt, 'N');

            let url_imagen = CryptoJS.AES.decrypt(response[0][0].url, _secret);
            let url_imagen_string = CryptoJS.enc.Utf8.stringify(url_imagen);

            let Codigo = CryptoJS.AES.decrypt(
                response[0][0].Codigo,
                _secret
            );

            let Mensaje = CryptoJS.AES.decrypt(
                response[0][0].Mensaje,
                _secret
            );

            response[0][0].Codigo = CryptoJS.enc.Utf8.stringify(Codigo);
            response[0][0].Mensaje = CryptoJS.enc.Utf8.stringify(Mensaje);
            let ingreso = '000';
            console.log(response)
            if (response[0][0].Codigo) {
                ingreso = response[0][0].Codigo.replace(/ /g, "")
            }

            if (ingreso.toString() === '819') {
                return navigate("/recover-password");
                // cambiar clave
            } else if (ingreso.toString() === '853') {
                setIsCode(true)
            } else if (ingreso.toString() === '000') {
                setIsCode(false)
            }

            setImagenSeguridad(url_imagen_string)

            console.log(url_imagen_string)
        } catch (error) {
            console.log(error)
        }
    }

    const validateUser = async () => {
        setLoadingStep0(true)
        const api = new OpaMovilService(theme.api_url);
        try {
            const cedula_encrypt = CryptoJS.AES.encrypt(username, _secret).toString()

            const response = await api.consultacedulausuario(cedula_encrypt);

            const cedula_result = CryptoJS.AES.decrypt(response[0][0].cedula, 'opaApp');
            const cedula_result_string = CryptoJS.enc.Utf8.stringify(cedula_result);
            setCedula(cedula_result_string);
            localStorage.setItem("opa:movil:cedula:temp", cedula_result_string);

            if (cedula_result_string === "0") {
                setLoadingStep0(false)
                return alert("El usuario no existe")
            }

            await getSecurityInfo(cedula_result_string)

            setActiveStep(1);
        } catch (error) {
            console.log(error)
        }
        setLoadingStep0(false)
    }

    const Step1 = () => {
        return (
            <>
                <div style={{ height: 235, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={theme.logo_url} height="120" alt="Imagen logo entidad"></img>
                </div>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Usuario"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={username}
                    onChange={e => setUsername(e.target.value)}
                />
                <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={validateUser}
                    disabled={loadingStep0}
                >
                    Ingresar
                </Button>
            </>
        )
    }

    const login = async () => {
        if (!checked) {
            alert("Debe confirmar su imagen registrada")
            return;
        }

        setChecked(false);

        setActiveStep(2);

        const api = new OpaMovilService(theme.api_url);
        try {

            const params_auth = {
                operador: CryptoJS.AES.encrypt(cedula, _secret).toString(),
                PassWord: CryptoJS.AES.encrypt(password, _secret).toString(),
                codigo: CryptoJS.AES.encrypt(codigoSeguridad || "", _secret).toString(),
                esoperador: 'N'
            }

            const response = await api.login(params_auth);

            var Codigo = CryptoJS.AES.decrypt(
                response[0][0].Codigo,
                _secret
            );

            response[0][0].Codigo = CryptoJS.enc.Utf8.stringify(Codigo);

            var msg = CryptoJS.AES.decrypt(
                response[0][0].Mensaje,
                _secret
            );

            response[0][0].Mensaje = CryptoJS.enc.Utf8.stringify(msg)
            
            if ( ['820', '854'].indexOf(response[0][0].Codigo.toString())  !== -1) {
                setActiveStep(0);
                return alert(CryptoJS.enc.Utf8.stringify(msg))
            } else if (response[0][0].Codigo.toString() === '819') {
                alert(CryptoJS.enc.Utf8.stringify(msg))
                return navigate("/recover-password");
                // cambiar clave
            }

            var nombreasociado = CryptoJS.AES.decrypt(
                response[0][0].nombreusuario,
                _secret
            );

            response[0][0].nombreusuario = CryptoJS.enc.Utf8.stringify(nombreasociado);

            if (response[0][0].id) {
                var id = CryptoJS.AES.decrypt(
                    response[0][0].id,
                    _secret
                );

                response[0][0].id = CryptoJS.enc.Utf8.stringify(id);
            }

            // app movil save vars
            sessionStorage.setItem("opa:movil:jwt", response[0][0].token)
            localStorage.setItem("opa:movil:esoperador", response[0][0].esoperador)
            localStorage.setItem("opa:movil:cedula", cedula)
            localStorage.setItem("opa:movil:usuario", response[0][0].nombreusuario)
            localStorage.setItem("opa:movil:ultimo_ingreso", response[0][0].ultimoingreso)
            localStorage.setItem("opa:movil:id", response[0][0].id)

            if (auth.signin) auth.signin(response[0][0].token);
            navigate("/home");

        } catch (error) {
            console.log(error)
            setActiveStep(0);
            return alert(response[0][0].Mensaje || "Error en el servicio. intente mas tarde")
        }
    }

    const Step3 = () => {
        return (
            <>
                <div style={{ height: 220 }}></div>
                <div style={{ textAlign: 'center', width: '100%' }}>
                    <CircularProgress />
                </div>
                <div style={{ height: 112 }}></div>
            </>
        )
    }


    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div>
            <Container component="main" maxWidth="xs" style={{ backgroundColor: "#F6F6F6", borderRadius: '15px' }}>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={styleModal}>
                        <p>Se abrirá una nueva ventana sólo para el manejo de Servicios en línea, en la cual se solicitará tu cédula y tu clave personal para acceder al servicio.</p>
                        <br />
                        <p>Por políticas de seguridad <strong>{theme.sigla}</strong> nunca solicitará información personal o de tus cuentas a través de correo electrónico.</p>
                        <br />
                        <p>Recuerda cambiar periódicamente tu clave de acceso.</p>
                        <br />
                        <p>Siempre que vayas a realizar transacciones por Internet, hazlo desde tu computador personal en casa u oficina.</p>
                        <br />
                        <p>Siempre que desees ingresar a realizar consultas referentes a tus saldos, digita desde tu navegador la dirección  <strong>{theme.url_company}</strong>, nunca ingreses a través de enlaces en correos electrónicos o en otros sitios de Internet.</p>
                        <br />
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'flex-end' }}>
                            <Button variant="contained" color="primary" onClick={handleClose}>Cerrar</Button>
                        </div>
                    </Box>
                </Modal>

                <CssBaseline />
                <div className={classes.paper}>
                    <form className={classes.form} noValidate>
                        {
                            activeStep === 0 ? <Step1 /> :
                                activeStep === 1 ? <>
                                    <Card>
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                alt="Imagen seguridad"
                                                height="140"
                                                className={classes.security_image}
                                                image={imagenSeguridad}
                                            />
                                            <CardContent>
                                                <Typography variant="body2" color="textSecondary" component="p">
                                                    <FormControlLabel
                                                        control={<Checkbox checked={checked} name="imagen" onChange={() => setChecked(!checked)} />}
                                                        label="Confirme que esta es su imagen y frase registrada"
                                                    />
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>

                                    {isCode ?
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="codigoSeguridad"
                                            label="Ingrese código"
                                            name="codigoSeguridad"
                                            autoFocus
                                            value={codigoSeguridad}
                                            onChange={e => setCodigoSeguridad(e.target.value)}
                                        /> : null
                                    }

                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        type="password"
                                        id="password"
                                        label="Contraseña"
                                        name="password"
                                        autoComplete="password"
                                        autoFocus
                                        value={password}
                                        onChange={e => setPassword(e.target.value)}
                                    />
                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            <Button
                                                type="button"
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                className={classes.submit}
                                                onClick={() => setActiveStep(0)}
                                                startIcon={<ArrowBackIosIcon />}
                                            >
                                                Regresar
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button
                                                type="button"
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                className={classes.submit}
                                                onClick={() => login()}
                                            >
                                                Ingresar
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </> :
                                    activeStep === 2 ? <Step3 /> :
                                        null
                        }
                        <Grid container>
                            <Grid item xs>
                                {activeStep === 0 ?
                                    <LinkRouter to="/recover-user">
                                        {"Recordar usuario"}
                                    </LinkRouter> :
                                    activeStep === 1 ?
                                        <LinkRouter to="/recover-password">
                                            {"¿Olvidaste tu contraseña?"}
                                        </LinkRouter> :
                                        null
                                }
                            </Grid>
                            <Grid item>
                                <LinkRouter to="/register">
                                    {"¿No tienes una cuenta?"}
                                </LinkRouter>
                            </Grid>
                        </Grid>
                    </form>
                </div>
                <Box mt={2} style={{ textAlign: 'center' }}>
                    <Button startIcon={<Icon icon={lockFill} />} onClick={handleOpen}>
                        Mensaje de Seguridad
                    </Button>
                </Box>
                <br />
            </Container>
            <br />
            
            <p style={{textAlign: 'center', color: '#6f6f6f'}}>Al ingresar al sitio web aceptas los términos y condiciones de uso.</p>
            <br />
            <br />
            <Copyright />
            <br />
        </div>

    );
}