import React, { useEffect, useContext, useState, useCallback } from "react";
import OpaCDService from "../../api/OpaCDService";
import OpaMovilService from "../../api/OpaMovilService";
import { CompanyContext } from "../../store";
import axios from "axios";

import * as CryptoJS from 'crypto-js';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box';

const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 4,
    bgcolor: 'background.paper',
    border: '1px solid rgba(0,0,0,0.2)',
    borderRadius: '6px',
    boxShadow: '0 3px 9px rgba(0,0,0,0.5)',
};

const CreditosDigitales = () => {
    const [cdConfig, setCdConfig] = useState()
    const [cdToken, setCdToken] = useState()
    
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [cuentasAhorros, setCuentasAhorros] = useState([])
    const [tienePreaprobado, setTienePreaprobado] = useState(false)
    const [mensajePreaprobado, setMensajePreaprobado] = useState('')
    const [creditosDigitalesParametros, setCreditosDigitalesParametros] = useState()

    const theme = useContext(CompanyContext);
    const _secret = process.env.REACT_APP_OPAMOVIL_SECRET || ""


    const obtenerCuentasDeAhorros = async () => {
        const operador = CryptoJS.AES.encrypt(localStorage.getItem("opa:movil:cedula"), _secret).toString()
        const parametters = {
            CedulaAsociado: operador,
            MensajeError: "",
            NumeroError: "",
            Tipoerror: "",
            operador: operador,
            esoperador: 'N',
        };

        const token = sessionStorage.getItem("opa:movil:jwt")
        const api = new OpaMovilService(theme.api_url, token)

        try {
            const data = await api.origenAhorros(parametters)
            if (data[0][0].Codigo != undefined) {
                if (data[0][0].Codigo == "401") { return localStorage.clear() } else if (data[0][0].Codigo == "404") {
                    console.log("No tiene cuentas de ahorros para traslados")
                    return
                }
            }

            let cuentasDeAhorros = DesencriptarDataAhorro(data)

            cuentasDeAhorros = cuentasDeAhorros[0].filter(cuenta => cuenta.Linea);

            if (cuentasDeAhorros.length <= 0) {
                return;
            }

            cuentasDeAhorros = cuentasDeAhorros.map(cuenta => {
                return `${cuenta.Linea}-${cuenta.NumeroCuenta.trim()}`
            });

            setCuentasAhorros(cuentasDeAhorros)
        } catch (error) {
            console.log(error)
        }
    }

    const DesencriptarDataAhorro = (data) => {
        var empieza = 0
        var cuantos = data[0].length
        if (data[0][0].NumeroCuenta != undefined) {
            while (empieza >= 0 && empieza < cuantos) {
                var NumeroCuenta = CryptoJS.AES.decrypt(
                    data[0][empieza].NumeroCuenta,
                    _secret
                );

                var SaldoTotal = CryptoJS.AES.decrypt(
                    data[0][empieza].SaldoTotal,
                    _secret
                );
                data[0][empieza].NumeroCuenta = CryptoJS.enc.Utf8.stringify(NumeroCuenta);
                data[0][empieza].SaldoTotal = CryptoJS.enc.Utf8.stringify(SaldoTotal);
                empieza++
            }
        }

        return data
    }

    const recuperarCupo = useCallback(async () => {
        const response = await axios.get(cdConfig.urlHost + cdConfig.routeGetAmount + '?id=' + localStorage.getItem("opa:movil:cedula"), {
            headers: {
                Authorization: 'Bearer ' + cdToken
            }
        })

        if (response.data.object) {
            setTienePreaprobado(true)
            var cupoFormateado = new Intl.NumberFormat("de-DE").format(response.data.object.creditAmount)
            setMensajePreaprobado("Está listo para ti un crédito aprobado por: $" + cupoFormateado)
            setCreditosDigitalesParametros(response.data.object);
        } else {
            setTienePreaprobado(false)
        }
    }, [cdConfig, cdToken])

    const obtenerToken = async () => {
        const token = sessionStorage.getItem("opa:movil:jwt")
        const api = new OpaCDService(theme.api_url, token)

        try {
            const response = await api.cdToken()
            setCdToken(response[0].token)
            obtenerCuentasDeAhorros()
        } catch (error) {
            console.log(error)
        }
    }

    const obtenerConfiguracion = async () => {
        const token = sessionStorage.getItem("opa:movil:jwt")
        const api = new OpaCDService(theme.api_url, token)

        try {
            const response = await api.configuration()
            setCdConfig(response[0])
            obtenerToken()
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        obtenerConfiguracion()
    }, [])

    useEffect(() => {
        if (cdConfig && cdToken && cuentasAhorros) {
            if (cuentasAhorros.length > 0) recuperarCupo()
        }
    }, [cdConfig, cdToken, cuentasAhorros])


    return tienePreaprobado && creditosDigitalesParametros? <div style={{width: 'calc(100% - 20px)', border: '1px solid', borderRadius: '10px', margin: '10px', padding: '5px', textAlign: 'center'}}>
        <span>{mensajePreaprobado}</span> <br/><br/>
        <Button variant="contained" onClick={handleOpen} color="primary" disableElevation>
            Lo Quiero!
        </Button>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={styleModal} className="cd-modal">
            <creditos-digitales 
                id={creditosDigitalesParametros.id}
                token={"Bearer " + cdToken}
                api={"https://creditosdigitalestest.opa.com.co/api"}  
                count={cuentasAhorros.join(",")}
            />
            </Box>
        </Modal>
    </div>: null
}

export default CreditosDigitales;