import React, { useContext, useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import { CompanyContext } from '../../../store';
import OpaMovilService from '../../../api/OpaMovilService';
import * as CryptoJS from 'crypto-js';
import { useNavigate, Link as LinkRouter } from "react-router-dom";
import { Copyright } from './OpaMovil';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useAuth } from '../../../auth/ProvideAuth';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    security_image: {
        objectFit: 'contain'
    }
}));

export function OpaMovilRecoverPassword() {
    const theme = useContext(CompanyContext);
    let navigate = useNavigate();
    let auth = useAuth();

    useEffect(() => {
        sendCodeVerification("Genera")
        // eslint-disable-next-line
    }, [])

    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const [code, setCode] = useState("");
    const [clave1, setClave1] = useState("");
    const [clave2, setClave2] = useState("");

    const [pregunta1, setPregunta1] = useState("");
    const [pregunta2, setPregunta2] = useState("");
    const [pregunta3, setPregunta3] = useState("");

    const [pregunta1Text, setPregunta1Text] = useState("");
    const [pregunta2Text, setPregunta2Text] = useState("");
    const [pregunta3Text, setPregunta3Text] = useState("");
    const [pregunta1Id, setPregunta1Id] = useState("");
    const [pregunta2Id, setPregunta2Id] = useState("");
    const [pregunta3Id, setPregunta3Id] = useState("");
    
    
    const [dialongAlert, setDialongAlert] = useState(false);
    const [dialongAlertText, setDialongAlertText] = useState("");
    const _secret = process.env.REACT_APP_OPAMOVIL_SECRET || ""

    
    const handleAlertClose = () => {
        setDialongAlert(false);
    };
    
    const sendCodeVerification = async (tipo: string) => {
        const cedula = localStorage.getItem("opa:movil:cedula:temp") || ""

        if (!cedula) return;

        const paramettersCodigoRecuperaClave = {
            Tipo: tipo,
            Operador: CryptoJS.AES.encrypt(cedula, _secret).toString(),
            CedulaAsociado: CryptoJS.AES.encrypt(cedula, _secret).toString(),
            codigo: CryptoJS.AES.encrypt(code, _secret).toString(),
            esoperador: 'N'
        }

        const api = new OpaMovilService(theme.api_url);
        try {
            const response = await api.validacionesrecuperacion(paramettersCodigoRecuperaClave);
            var Mensaje = CryptoJS.AES.decrypt(
                response[0][0].Mensaje,
                _secret
            );
    
            response[0][0].Mensaje = CryptoJS.enc.Utf8.stringify(Mensaje);
            const respuestaCodigo = response[0][0].Codigo.replace(/ /g, "")

            if (respuestaCodigo === "128") {
                // debe ingresar codigo
                setActiveStep(128)
            } else if(respuestaCodigo === "132"){
                setActiveStep(132)
            } else if(respuestaCodigo === "131" && response[0][0].preguntasclaveprincipal === "S"){
                setActiveStep(131)
                cargarPreguntas()
            } else if(respuestaCodigo === "131" && response[0][0].preguntasclaveprincipal === "N"){
                setActiveStep(132)
            } else {
                setDialongAlert(true)
                setDialongAlertText(response[0][0].Mensaje)
            }

            console.log(response)
        } catch (error) {
            console.log(error)
        }
    }

    const Step128 = () => {
        return (
            <><div style={{ height: 220 }}>Se ha enviado un correo con el código de validación por favor verifique</div>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Codigo"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={code}
                    onChange={e => setCode(e.target.value)}
                />
                <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={validateCode}
                >
                    Ingresar
              </Button>
            </>
        )
    }

    const cargarPreguntas = async () => {
        const cedula = localStorage.getItem("opa:movil:cedula:temp") || ""

        const params = {
            operador:  CryptoJS.AES.encrypt(cedula, _secret).toString(),
            esoperador: 'N',
            xml: CryptoJS.AES.encrypt("<root><parameters><Tipo>CONPRE</Tipo></parameters></root>", _secret).toString()
        }

        const api = new OpaMovilService(theme.api_url);
        try {
            const response = await api.preguntasseguridad(params);

            if(response[0].length !== 0){
                setPregunta1Text(response[0][0].pregunta)
                setPregunta2Text(response[0][1].pregunta)
                setPregunta3Text(response[0][2].pregunta)
                setPregunta1Id(response[0][0].idregistro)
                setPregunta2Id(response[0][1].idregistro)
                setPregunta3Id(response[0][2].idregistro)
            }else{
                alert("Usted no ha registrado las preguntas de seguridad por favor hable con su Administrador")
                localStorage.clear();
                navigate("/login");
                return
            }
            
            console.log(response)
        } catch (error) {
            alert("Error intente luego")
            localStorage.clear();
            navigate("/login");
            return
        }
    }

    const validateCode = () => {
        sendCodeVerification("Valida")
    }

    const recupararClave = async () => {
        if(!clave1.trim() || !clave2.trim()){
            setDialongAlertText("Faltan campos por llenar ...Verifique por favor")
            setDialongAlert(true)
            return false;
        }
      
      
        if(clave1.length < 4 || clave1.length > 6 ) {
            setDialongAlertText("Clave minimo 4 y maximo 6 letras ...Verifique por favor")
            setDialongAlert(true)
            return false;
        }
      
        if(clave1 !== clave2){
            setDialongAlertText("Las contraseñas no coinciden ...Verifique por favor")
            setDialongAlert(true)
            return false;
        }

        const cedula = localStorage.getItem("opa:movil:cedula:temp") || ""

        const params = {
            USUARIO: CryptoJS.AES.encrypt(cedula, _secret).toString(),
            CLAVE: CryptoJS.AES.encrypt(clave1, _secret).toString()
        }

        const api = new OpaMovilService(theme.api_url);
        try {
            const response = await api.Recuperaclave(params);
            var Mensaje = CryptoJS.AES.decrypt(
                response[0][0].Mensaje,
                _secret
            );
    
            response[0][0].Mensaje = CryptoJS.enc.Utf8.stringify(Mensaje);
            const respuestaCodigo = response[0][0].Codigo.replace(/ /g, "")

            setDialongAlert(true)
            setDialongAlertText(response[0][0].Mensaje)

            if (respuestaCodigo === "133") {
                return navigate("/login");
            }
        } catch (error) {
            console.log(error)
            setDialongAlert(true)
            setDialongAlertText("Error intente luego")
        }
    }

    const enviarRespuestasPreguntas = async () => {
        if(!pregunta1.trim() || !pregunta2.trim() || !pregunta3.trim()){
            setDialongAlertText("Faltan campos por llenar ...Verifique por favor")
            setDialongAlert(true)
            return false;
        }

        const cedula = localStorage.getItem("opa:movil:cedula:temp") || ""

        const respuestas = {
            operador: CryptoJS.AES.encrypt(cedula, _secret).toString(),
            esoperador: 'N',
            xml: CryptoJS.AES.encrypt("<root><parameters>"+
            "<Tipo>CONRES</Tipo>"+
            "<pregunta>"+pregunta1Id+"</pregunta><respuesta>"+pregunta1+"</respuesta>"+
            "<pregunta1>"+pregunta2Id+"</pregunta1><respuesta1>"+pregunta2+"</respuesta1>"+
            "<pregunta2>"+pregunta3Id+"</pregunta2><respuesta2>"+pregunta3+"</respuesta2>"+
            "</parameters></root>", _secret).toString()
        }

        const api = new OpaMovilService(theme.api_url);
        try {
            const response = await api.preguntasseguridad(respuestas);

            var Mensaje = CryptoJS.AES.decrypt(
                response[0][0].Mensaje,
                _secret
            );
    
            response[0][0].Mensaje = CryptoJS.enc.Utf8.stringify(Mensaje);

            setDialongAlert(true)
            setDialongAlertText(response[0][0].Mensaje)

            if (Number(response[0][0].Codigo) === 125) {
                setActiveStep(132)
            }
        } catch (error) {
            alert("Error intente luego")
            localStorage.clear();
            navigate("/login");
            return
        }
    }

    const Step1 = () => {
        return <h1>Cargando...</h1>
    }

    return (
        <Container component="main" maxWidth="xs">
            <Dialog
                open={dialongAlert}
                onClose={handleAlertClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dialongAlertText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAlertClose} color="primary" autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>

            <CssBaseline />
            <div className={classes.paper}>
                {/* <Avatar className={classes.avatar} src={theme.logo_url} /> */}
                <form className={classes.form} noValidate>
                    {
                        activeStep === 0? <><Step1 /></> : 
                        activeStep === 128? <><Step128 /></> : 
                        activeStep === 131? <>
                            <p>Primera Pregunta: {pregunta1Text}</p>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="pregunta1"
                                label="Primera Respuesta"
                                name="pregunta1"
                                value={pregunta1}
                                onChange={e => setPregunta1(e.target.value)}
                            />
                            <p>Segunda Pregunta: {pregunta2Text}</p>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="pregunta2"
                                label="Segunda Respuesta"
                                name="pregunta2"
                                value={pregunta2}
                                onChange={e => setPregunta2(e.target.value)}
                            />
                            <p>Tercera Pregunta: {pregunta3Text}</p>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="pregunta3"
                                label="Tercera Respuesta"
                                name="pregunta3"
                                value={pregunta3}
                                onChange={e => setPregunta3(e.target.value)}
                            />
                            <Button
                                type="button"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={enviarRespuestasPreguntas}
                            >
                                Enviar
                            </Button>
                        </> : 
                        activeStep === 132? <>
                            <div style={{ height: 220 }}></div>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="clave1"
                                label="Contraseña"
                                name="clave1"
                                type="password"
                                value={clave1}
                                onChange={e => setClave1(e.target.value)}
                            />

                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                type="password"
                                id="clave2"
                                label="Confirme Contraseña"
                                name="clave2"
                                value={clave2}
                                onChange={e => setClave2(e.target.value)}
                            />
                            <Button
                                type="button"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={recupararClave}
                            >
                                Enviar
                            </Button>
                        </> :
                        null
                    }
                    {auth.user? null:
                        <Grid container>
                            <Grid item xs>
                                {activeStep === 0 ?
                                    <LinkRouter to="/recover-user">
                                        {"Recordar usuario"}
                                    </LinkRouter> :
                                    activeStep === 1 ?
                                        <LinkRouter to="/recover-password">
                                            {"¿Olviaste tu contraseña?"}
                                        </LinkRouter> :
                                        null
                                }
                            </Grid>
                            <Grid item>
                                <LinkRouter to="/register">
                                    {"¿No tienes una cuenta?"}
                                </LinkRouter>
                            </Grid>
                        </Grid>
                    }
                </form>
            </div>
            {auth.user? null:
                <Box mt={8}>
                    <Copyright />
                </Box>
            }
        </Container>
    );
}