import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { CompanyContext } from './store';
import { ProvideAuth } from './auth/ProvideAuth';
import { Home } from "./Home";
// theme
import ThemeConfig from './theme';
import {
  BrowserRouter as Router,
} from "react-router-dom";

import './App.css';

function App() {
  const [loading, setLoading] = useState(true);
  const [themeContext, setThemeContext] = useState({
    name: "",
    color: "",
    logo_url: "",
    api_type: "",
    api_url: "",
    url_company: "",
    sigla: "",
    modules_install: []
  });
  
  const [palleteConfig, setPalleteConfig] = useState({})

  useEffect(() => {
    fetch('/.netlify/functions/get-configuration?domain='+ window.location.host)
      .then(response => response.json())
      .then(data => {
        setThemeContext({
          name: data.result.name,
          color: data.result.color,
          logo_url: data.result.logo_url,
          api_type: data.result.api_type,
          api_url: data.result.api_url,
          modules_install: data.result.modules_install,
          url_company: data.result.url_company,
          sigla: data.result.sigla,
        });

        setPalleteConfig({
            primary: {
              main: data.result.color || "#000",
              contrastText: "#fff"
            }
        })

        setLoading(false);
      });
  }, [])

  return (
    <CompanyContext.Provider value={themeContext}>
        <ThemeConfig palleteConfig={palleteConfig} >
            {loading? 
            <>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    style={{height: '100vh'}}
                >
                    <Grid item xs={3}>
                        <img src={process.env.PUBLIC_URL + '/car.gif'} />
                    </Grid>   
                </Grid>
            </>
            :
            <ProvideAuth>
              <Router>
                <Home />
              </Router>
            </ProvideAuth>
            }
      </ThemeConfig>
    </CompanyContext.Provider>
  );
}

export default App;
