import LoginBase from './LoginBase';
import { OpaMovil } from '../../features/login/opa/OpaMovil';


// ----------------------------------------------------------------------

export default function Login() {
  return (
    <LoginBase>
        <OpaMovil />
    </LoginBase>
  );
}