import React from "react";

export interface ICompanyContext {
    name:  string;
    color:  string;
    logo_url:  string;
    api_type:  string;
    api_url:  string;
    modules_install: string[];
    sigla?: string;
    url_company?: string;
}

const companyContext: ICompanyContext = {
    name: "",
    color: "",
    logo_url: "",
    api_type: "",
    api_url: "",
    modules_install: []
};

export const CompanyContext = React.createContext(companyContext);
