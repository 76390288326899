import LoginBase from './LoginBase';
import { OpaMovilRecoverUser } from '../../features/login/opa/OpaMovilRecoverUser';


// ----------------------------------------------------------------------

export default function Login() {
  return (
    <LoginBase>
        <OpaMovilRecoverUser />
    </LoginBase>
  );
}