import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import shoppingBagFill from '@iconify/icons-eva/shopping-bag-fill';
import lockFill from '@iconify/icons-eva/lock-fill';
import closeSquareOutline from '@iconify/icons-eva/close-square-outline';
import moneyCalculator24Filled from '@iconify/icons-fluent/money-calculator-24-filled';
import piggyBank from '@iconify/icons-fa-solid/piggy-bank';
// ----------------------------------------------------------------------
const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'Estado de cuentas',
    path: '/home/accounts',
    icon: getIcon(pieChart2Fill)
  },
  {
    title: 'Simulador de CDAT',
    path: '/home/simulatorAH',
    icon: getIcon(piggyBank)
  },
  {
    title: 'Simulador de creditos',
    path: '/home/simulatorCR',
    icon: getIcon(moneyCalculator24Filled)
  },
  {
    title: 'Descuentos nomina',
    path: '/home/payrollDiscounts',
    icon: getIcon(lockFill)
  },
  {
    title: 'Certificados',
    path: '/home/certificates',
    icon: getIcon(shoppingBagFill)
  },
  {
    title: 'Salir',
    path: '/login',
    icon: getIcon(closeSquareOutline)
  },
];

export default sidebarConfig;
