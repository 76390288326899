import LoginBase from './LoginBase';
import { OpaMovilRegister } from '../../features/login/opa/OpaMovilRegister';


// ----------------------------------------------------------------------

export default function Login() {
  return (
    <LoginBase>
        <OpaMovilRegister />
    </LoginBase>
  );
}