import { useContext } from 'react';
import { styled } from '@material-ui/core/styles';
import { Card, Container, Typography } from '@material-ui/core';
// layouts
// components
import Page from '../../components/Page';
import { MHidden } from '../../components/@material-extend';
// ----------------------------------------------------------------------
import { CompanyContext } from '../../store';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function LoginBase(props) {
    const theme = useContext(CompanyContext);

  return (
    <RootStyle title="Login">

      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Hola, bienvenido a tu Sucursal Virtual
          </Typography>
          <img src={process.env.PUBLIC_URL + '/img/bienvenido-opatransaccional.jpg'} alt="login" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
            <p style={{textAlign: 'center', fontSize: '25px', color: theme.color}}>
                <strong>{theme.name}</strong>
            </p>
            {props.children}
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}