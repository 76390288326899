import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import OpaMovilService from '../../api/OpaMovilService';
import * as CryptoJS from 'crypto-js';
import { CompanyContext } from '../../store';
import TextField from '@material-ui/core/TextField';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import Card from '@material-ui/core/Card';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Alert from '@material-ui/core/Alert';

import Backdrop from '@material-ui/core/Backdrop';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    table: {},
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        background: 'linear-gradient(75deg, rgba(255, 255, 255, 0.48) 0%, rgb(255, 255, 255) 100%)'
    },
}));

interface TypePayrollDetail {
    n: string;
    cedula: string;
    valor: number;
    codlinea: string;
    codcuenta: string;
    numerocuenta: string;
    tiponovedad: string;
    codempresa: string;
    periodo:string;
    nroperido: number;
    nroperidodeduce: number;
    agencia: number;
    pertenece: string;
    coddestino: string;
    fechatrabajo: string;
    fechasistema: string;
    documentonota: string;
    consecutivonovedad: number;
    tipoingreso: string;
    interescalculado: number;
    abonocapital: number;
    valorentidad: number;
    formapago: string;
    valorasentado: number;
    idempresasanterior: number;
    valorrecibido: number;
    documentoreferencia: string;
    fecharecibopago?: string;
}

const PayrollDiscounts = () => {
    const classes = useStyles();

    const theme = useContext(CompanyContext);
    const _secret = process.env.REACT_APP_OPAMOVIL_SECRET || ""

    const [dates, setDates] = useState([])
    const [seletedDate, setSeletedDate] = useState("")

    const [discountsAH, setDiscountsAH] = useState<TypePayrollDetail[]>([])
    const [discountsCR, setDiscountsCR] = useState<TypePayrollDetail[]>([])
    const [discountsNV, setDiscountsNV] = useState<TypePayrollDetail[]>([])
    const [discountsNM, setDiscountsNM] = useState<TypePayrollDetail[]>([])

    
    const [panel1, setPanel1] = useState(true)
    const [panel2, setPanel2] = useState(true)
    const [panel3, setPanel3] = useState(true)
    const [panel4, setPanel4] = useState(true)
    const [loading, setLoading] = useState(false)

    const baseParams: {
        cedula: string,
        caso: number,
        fechadevolver: string
    } = {
        cedula: CryptoJS.AES.encrypt(localStorage.getItem("opa:movil:id") || "", _secret).toString(),
        caso: 2,
        fechadevolver: '',
    }

    const getDatesForDiscounts = async () => {
        setLoading(true)
        const params = Object.assign({}, baseParams);
        const token = sessionStorage.getItem("opa:movil:jwt");
        const api = new OpaMovilService(theme.api_url, token);

        try {
            const response = await api.descuentosNomina(params);
            setDates(response[0])
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }

    useEffect(() => {
        getDatesForDiscounts()
    }, [])

    const getPayrollDetail = async () => {
        if (!seletedDate) {
            return alert("Falta seleccionar una fecha valida");
        }

        setLoading(true)

        const params = Object.assign({}, baseParams);
        const token = sessionStorage.getItem("opa:movil:jwt");
        const api = new OpaMovilService(theme.api_url, token);
        
        params.fechadevolver = seletedDate;
        params.caso = 3;

        try {
            const response = await api.descuentosNomina(params);
            console.log(response[0])
            setDiscountsAH(
                response[0].filter(
                    (item: TypePayrollDetail) => ['APOOR','APER','AVIST','ACONT'].indexOf(item.tiponovedad.trim()) !== -1
                )
            )
            setDiscountsCR(
                response[0].filter(
                    (item: TypePayrollDetail) => ['CRED'].indexOf(item.tiponovedad.trim()) !== -1
                )
            )
            setDiscountsNV(
                response[0].filter(
                    (item: TypePayrollDetail) => ['NVAR','NCCS','NVCS'].indexOf(item.tiponovedad.trim()) !== -1
                )
            )
            setDiscountsNM(
                response[0].filter(
                    (item: TypePayrollDetail) => ['APOOR','APER','AVIST','ACONT','CRED','NVAR','NCCS','NVCS'].indexOf(item.tiponovedad.trim()) == -1
                )
            )
        } catch (error) {
            console.log(error)
        }

        setLoading(false)
    }

    return <div className={classes.root}>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Paper className={classes.paper}> <h1>Descuentos de nomina</h1> </Paper>
            </Grid>

            <Grid item xs={12} md={12}>
                <Card>
                    <Paper className={classes.paper}>
                        <TextField
                            select
                            size="small"
                            label="Seleccione una fecha:"
                            SelectProps={{
                                native: true,
                            }}
                            variant="outlined"
                            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                setSeletedDate(ev.target.value)
                            }}
                            style={{width: '250px'}}
                        >
                            <option value="" />
                            {dates.map((item, index) =>
                                <option key={`dates${index}`} value={item['']}>{moment( item[''], 'YYYYMMDD').format('YYYY-MM-DD')}</option>
                            )}
                        </TextField>
                        {" "}
                        <Button variant="contained" color="primary" disableElevation onClick={getPayrollDetail}>
                            Consultar
                        </Button>
                    </Paper>
                </Card>
            </Grid>
            
            <Grid item xs={12} md={12}>
                {discountsAH.length > 0?
                <Card>
                    <Accordion expanded={panel1} onChange={() => setPanel1(!panel1)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="ah-panel-content"
                            id="ah-panel-header"
                            style={{backgroundColor: 'rgb(230, 230, 230)'}}
                        >
                            <Typography>Descuentos por Aportes y Ahorros</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TableContainer component={Paper}>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell width="30%">Concepto</TableCell>
                                        <TableCell width="17%">Codigo</TableCell>
                                        <TableCell width="17%">Fecha</TableCell>
                                        <TableCell width="17%">Número Cuenta</TableCell>
                                        <TableCell width="17%">Valor Descuento</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {discountsAH.map( (item: TypePayrollDetail) => 
                                        <TableRow>
                                            <TableCell>{item.n}</TableCell>
                                            <TableCell>{item.codcuenta}</TableCell>
                                            <TableCell>{moment.utc(item.fechasistema).format('YYYY-MM-DD')}</TableCell>
                                            <TableCell>{item.numerocuenta}</TableCell>
                                            <TableCell align="right">
                                                <NumberFormat value={item.valor || 0} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        </AccordionDetails>
                    </Accordion>
                </Card> : <Alert severity="info">No Tiene Descuentos por Aportes y Ahorros</Alert>}
            </Grid>

            <Grid item xs={12} md={12}>
                {discountsCR.length > 0?
                <Card>
                    <Accordion expanded={panel2} onChange={() => setPanel2(!panel2)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="ah-panel-content"
                            id="ah-panel-header"
                            style={{backgroundColor: 'rgb(230, 230, 230)'}}
                        >
                            <Typography>Descuentos por Creditos</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TableContainer component={Paper}>
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width="30%">Concepto</TableCell>
                                            <TableCell width="17%">Codigo</TableCell>
                                            <TableCell width="17%">Fecha</TableCell>
                                            <TableCell width="17%">Número Cuenta</TableCell>
                                            <TableCell width="17%">Valor Descuento</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {discountsCR.map( (item: TypePayrollDetail) => 
                                            <TableRow>
                                                <TableCell>{item.n}</TableCell>
                                                <TableCell>{item.codcuenta}</TableCell>
                                                <TableCell>{moment.utc(item.fechasistema).format('YYYY-MM-DD')}</TableCell>
                                                <TableCell>{item.numerocuenta}</TableCell>
                                                <TableCell align="right">
                                                    <NumberFormat value={item.valor || 0} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </AccordionDetails>
                    </Accordion>
                </Card> : <Alert severity="info">No Tiene Descuentos por Creditos</Alert>}
            </Grid>

            <Grid item xs={12} md={12}>
                {discountsNV.length > 0?
                <Card>
                    <Accordion expanded={panel3} onChange={() => setPanel3(!panel3)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="ah-panel-content"
                            id="ah-panel-header"
                            style={{backgroundColor: 'rgb(230, 230, 230)'}}
                        >
                            <Typography>Descuentos por Novedades</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TableContainer component={Paper}>
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width="30%">Concepto</TableCell>
                                            <TableCell width="17%">Codigo</TableCell>
                                            <TableCell width="17%">Fecha</TableCell>
                                            <TableCell width="17%">Número Cuenta</TableCell>
                                            <TableCell width="17%">Valor Descuento</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {discountsNV.map( (item: TypePayrollDetail) => 
                                            <TableRow>
                                                <TableCell>{item.n}</TableCell>
                                                <TableCell>{item.codcuenta}</TableCell>
                                                <TableCell>{moment.utc(item.fechasistema).format('YYYY-MM-DD')}</TableCell>
                                                <TableCell>{item.numerocuenta}</TableCell>
                                                <TableCell align="right">
                                                    <NumberFormat value={item.valor || 0} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </AccordionDetails>
                    </Accordion>
                </Card>
                : <Alert severity="info">No Tiene Descuentos por Novedades</Alert>
                }
                
            </Grid>

            <Grid item xs={12} md={12}>
                {discountsNM.length > 0?
                <Card>
                    <Accordion expanded={panel4} onChange={() => setPanel4(!panel4)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="ah-panel-content"
                            id="ah-panel-header"
                            style={{backgroundColor: 'rgb(230, 230, 230)'}}
                        >
                            <Typography>Descuentos por Nomina</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TableContainer component={Paper}>
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width="30%">Concepto</TableCell>
                                            <TableCell width="17%">Codigo</TableCell>
                                            <TableCell width="17%">Fecha</TableCell>
                                            <TableCell width="17%">Número Cuenta</TableCell>
                                            <TableCell width="17%">Valor Descuento</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {discountsNM.map( (item: TypePayrollDetail) => 
                                            <TableRow>
                                                <TableCell>{item.n}</TableCell>
                                                <TableCell>{item.codcuenta}</TableCell>
                                                <TableCell>{moment.utc(item.fechasistema).format('YYYY-MM-DD')}</TableCell>
                                                <TableCell>{item.numerocuenta}</TableCell>
                                                <TableCell align="right">
                                                    <NumberFormat value={item.valor || 0} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </AccordionDetails>
                    </Accordion>
                </Card> : <Alert severity="info">No Tiene Descuentos por Nomina</Alert>}
                </Grid>
            </Grid>
            
        <Backdrop open={loading} className={classes.backdrop}>
            <img src={process.env.PUBLIC_URL + '/car.gif'} />
        </Backdrop>
    </div>
}

export default PayrollDiscounts;